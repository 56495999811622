import Vue from 'vue'
import XKFOrder from '@/components/XKFOrder.vue';
import debounce from 'lodash/debounce';

const XKFOrderPlugin = {
	install(Vue) {
		// 注册全局方法
		Vue.prototype.$tip = (options = {}) => {
			// 创建一个临时的通知组件实例
			const Constructor = Vue.extend(XKFOrder);
			const instance = new Constructor({
				propsData: {
					options
				}
			});

			// 将实例挂载到body上
			instance.$mount();
			document.body.appendChild(instance.$el);
		};
	}
};

Vue.use(XKFOrderPlugin);

export function XKFInit(z, h, i, m, a, j, s) {
	z[i] = z[m] || function() {
		(z[m].a = z[m].a || []).push(arguments)
	};
	j = h.createElement(i),
		s = h.getElementsByTagName(i)[0];
	j.async = true;
	j.charset = 'UTF-8';
	j.setAttribute("data-json", JSON.stringify({
		id: 9090,
		token: 'de0e137fa395feb53d5d6a103efddb0d',
		image: '/static/home/img/logo_x120.png',
		customer: 0, // 控制是否显示客服图标，0为显示，1为不显示
		language: 'ZH' // 控制显示语言，ZH为中文，EN为英文
	}));
	j.id = "xiaokefu_js";
	j.src = 'https://pcjs.xiaokefu.com.cn/static/pc_Access/dist/pcAccess.js?version=' + parseInt(
		new Date().getTime() / 3600000);
	s.parentNode.insertBefore(j, s);

	// 初始化事件监听
	listeningInit()

	setTimeout(() => {
		XKFChange()
	}, 3000)
}

export function XKFOpen(data) {
	ZM.setUserInfo({
		province: '', // 省会：
		city: '', //  城市
		weixin: '', // 微信
		mobile: data.mobile, // 电话  数字
		age: '', //  年龄 数字
		sex: '', // 性别：1是男   2是女
		source: '厂商端', // 来源
		note: '', // 备注
		nickName: data.nickName, // 昵称
		openid: '', // 用户ID
		tag_id: '952503',
		orderNo: data.tmporderid
	});
	ZM.open();

	Vue.prototype.$tip({
		...data,
		confirm: () => {
			let obj = {
				content: `【订单编号】：${data.tmporderid}\n\n【商品名称】：${data.tmpTitle}\n\n【商品数量】：${data.tmpordernum}`
			}
			XKFSendOrder(obj)
		}
	});
}

function listeningInit() {
	Vue.prototype.$bus.$on('XKFSendOrder', XKFSendOrder)
}

function XKFSendOrder(data) {
	// ZM.sendTextMessage({content: 'xxxxxx2'})
	// content里面可以用\n或者\r\n换行，目前只支持文本回复
	ZM.sendTextMessage(data)
}

function XKFChange() {
	// 选择目标节点
	const targetNode = document.getElementById('ZMFloatBtn');

	// 创建一个观察者对象
	const observer = new MutationObserver(debounce(
		function(mutationsList, observer) {
			for (let mutation of mutationsList) {
				if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
					const displayValue = targetNode.style.display;
					console.log('display 属性变化为:', displayValue);
					// 在这里处理 display 属性变化
					if (displayValue == 'block') {
						Vue.prototype.$bus.$emit('XKFOrderClose')
					}
				}
			}
		},
		0, {
			leading: false,
			trailing: true
		}
	));

	// 观察目标节点的 attributes 变化
	const config = {
		attributes: true
	};

	// 传入目标节点和观察选项并开始观察
	observer.observe(targetNode, config);

	// 以后，你可以随意改变元素的 display 属性，观察者会捕捉到这个变化
	// targetNode.style.display = 'none'; // 这将会被监听到

}