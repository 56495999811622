import Vue from 'vue'
import Vuex from 'vuex'
import {
	ConfigGroup,
	UserGetUserInfo,
	UserGetSubAccountMenu
} from '../common/api.js'


Vue.use(Vuex)

// 用户信息
let token = ''
let userInfo = {}
let orderStatusList = [{
	label: '待付款',
	value: 100,
	color: '#f0a800'
}, {
	label: '待接单',
	value: 200,
	color: '#944806'
}, {
	label: '回收申请中',
	value: 210,
	color: '#de770f'
}, {
	label: '待预约',//预约中
	value: 300,
	color: '#944806'
}, {
	label: '回收待完工',//回收待完工
	value: 310,
	color: '#06b649'
}, {
	label: '待施工', // 已派单
	value: 400,
	color: '#f0a800'
}, {
	label: '施工中',
	value: 500,
	color: '#f0a800'
}, {
	label: '待配件',
	value: 510,
	color: '#3665e3'
}, {
	label: '待验收',
	value: 600,
	color: '#F5F5DC'
}, {
	label: '待返件',
	value: 650,
	color: '#F5F5DC'
}, {
	label: '已完成', // 已完单
	value: 700,
	color: '#46c26f'
}, {
	label: '已取消',//已取消
	value: 800,
	color: '#485970'
}, {
	label: '售后',
	value: 900,
	color: '#f0a800'
}]

let orderNodeList = [{
	label: '创建订单成功',
	value: 110
}, {
	label: '抢单待审核',
	value: 120
}, {
	label: '抢单审核驳回',
	value: 130
}, {
	label: '支付成功',
	value: 210
}, {
	label: '指派师傅',
	value: 220
}, {
	label: '指派师傅确认',
	value: 230
}, {
	label: '指派师傅拒绝',
	value: 240
}, {
	label: '师傅接单',
	value: 310
}, {
	label: '取消回收申请',
	value: 320
}, {
	label: '取消回收驳回',
	value: 330
}, {
	label: '预约上门',
	value: 410
}, {
	label: '修改时间',
	value: 420
}, {
	label: '打卡成功',
	value: 510
}, {
	label: '安装材料提交',
	value: 520
}, {
	label: '待配件',
	value: 530
}, {
	label: '配件已发货',
	value: 540
}, {
	label: '配件已到达',
	value: 550
}, {
	label: '完工申请',
	value: 560
}, {
	label: '完工申请驳回',
	value: 564
}, {
	label: '完工申请通过',
	value: 562
}, {
	label: '保外付款申请',
	value: 570
}, {
	label: '保外付款成功',
	value: 572
}, {
	label: '评价完成',
	value: 670
}, {
	label: '返件待申核',
	value: 630
}, {
	label: '返件申请通过',
	value: 632
}, {
	label: '返件申请驳回',
	value: 634
}, {
	label: '促好评待审核',
	value: 650
}, {
	label: '返件申核通过',
	value: 640
}, {
	label: '促评申核通过',
	value: 660
}, {
	label: '促评申核驳回',
	value: 664
}, {
	label: '评价完成',
	value: 670
}, {
	label: '订单完成',
	value: 710
}, {
	label: '已关闭',
	value: 810
}, {
	label: '售后申请中',
	value: 910
}, {
	label: '售后进行中',
	value: 920
}, {
	label: '售后申请驳回',
	value: 930
}, {
	label: '售后完成',
	value: 940
}]

let orderLabelList = [{
	label: '加急',
	value: '加急'
}, {
	label: '保内',
	value: '保内'
}, {
	label: '保外',
	value: '保外'
}, {
	label: '安装',
	value: '安装'
}, {
	label: '维修',
	value: '维修'
}, {
	label: '清洗',
	value: '清洗'
}, {
	label: '调制/检测/定位/教学',
	value: '调制/检测/定位/教学'
}]

let orderDateList = [{
	label: '近一周',
	value: '近一周'
}, {
	label: '近一个月',
	value: '近一个月'
}, {
	label: '近三个月',
	value: '近三个月'
}, {
	label: '近半年',
	value: '近半年'
}, {
	label: '近一年',
	value: '近一年'
}]

export default new Vuex.Store({
	state: {
		imgRoot: '',
		titBar: 0,
		nationality: null,
		current: 3,
		token: localStorage.getItem("token") || token, //用户校验
		userInfo: localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")) :
		userInfo, //用户信息
		isSubAccountFlag: localStorage.getItem("isSubAccountFlag") || '', // 主账号0，子账号1
		userName: localStorage.getItem("userName") || '', //记住账号密码
		password: localStorage.getItem("password") || '', //记住账号密码
		searchData: "", //搜索变量
		orderStatusList: orderStatusList, // 订单状态
		orderNodeList: orderNodeList, // 订单节点
		orderLabelList: orderLabelList, // 订单标签
		orderDateList: orderDateList, // 订单日期
		pc: localStorage.getItem("pc") ? JSON.parse(localStorage.getItem("pc")) : '', // pc端基础信息
		quicklyOrder: {}, // 快速下单
		againOrder: {}, // 再次下单
		userPermissions: [], // 用户权限
		isElImageViewerWrapperShow: false, // 是否在预览图片
		
	},
	getters: {
		isLogin: state => {
			if (!state.token) {
				return false
			}
			return true;
		},
		isSubAccount: state => {
			if (!state.userInfo.subAccountFlag) {
				return false
			}
			return true;
		},
		permissions: (state,getters) => {
			if (!getters.isSubAccount) {
				return "*:*:*"
			} else {
				
			}
		}
	},
	mutations: {
		changeToken(state, data = token) {
			try {
				localStorage.setItem("token", data)
			} catch (error) {}
			state.token = data
			console.log("changeToken: ", data);
		},
		changeUserRemember(state, userRemember) {
			try {
				localStorage.setItem("isSubAccountFlag", userRemember.isSubAccountFlag)
				localStorage.setItem("userName", userRemember.userName)
				localStorage.setItem("password", userRemember.password)
			} catch (error) {}
			state.isSubAccountFlag = userRemember.isSubAccountFlag
			state.userName = userRemember.userName
			state.password = userRemember.password
		},
		changeUserInfo(state, data = userInfo) {
			try {
				localStorage.setItem("userInfo", JSON.stringify(data))
			} catch (error) {}
			state.userInfo = data
			console.log("changeUserInfo: ", data);
		},
		changePc(state, data = {}) {
			try {
				localStorage.setItem("pc", JSON.stringify(data))
			} catch (error) {}
			state.pc = data
			console.log("changePc: ", data);
		},
		changeQuicklyOrder(state, data = {}) {
			state.quicklyOrder = data
			console.log("changeQuicklyOrder: ", data);
		},
		changeAgainOrder(state, data = {}) {
			state.againOrder = data
			console.log("changeAgainOrder: ", data);
		},
		changeUserPermissions(state, data = []) {
			state.userPermissions = data
			console.log("changeUserPermissions: ", data);
		},
		changeIsElImageViewerWrapperShow(state, data = false) {
			state.isElImageViewerWrapperShow = data
			// console.log("changeIsElImageViewerWrapperShow: ", data);
		},

	},
	actions: {
		// 更新用户token
		updateToken({
			dispatch,
			commit
		},data='') {
			if (data) {
				return new Promise(async (resolve,reject) => {
					commit('changeToken', data)
					await dispatch('updateUserInfo')
					resolve()
				})
			} else {
				return new Promise(async (resolve,reject) => {
					commit('changeToken')
					commit('changeUserInfo')
					resolve()
				})
			}

		},
		// 更新用户信息
		updateUserInfo({
			dispatch,
			commit,
			state,
			getters
		}) {
			return new Promise((resolve,reject) => {
				UserGetUserInfo().then(
					async res => {
						commit('changeUserInfo', res.data)
						await dispatch('updateUserPermissions')
						resolve(res.data)
					},
					err => {
						reject(err)
					}
				)
			})

		},
		// 更新用户权限
		updateUserPermissions({
			commit,
			state,
			getters
		}) {
			return new Promise((resolve,reject) => {
				if (!getters.isSubAccount) {
					let permissions = ["*:*:*"]
					commit('changeUserPermissions', permissions)
					resolve()
				} else {
					UserGetSubAccountMenu(state.userInfo.id).then(
						res => {
							let permissions = []
							function getPermissions(arr) {
								arr.forEach(v => {
									if (v.permissions && v.isHave == 1) {
										permissions.push(v.permissions)
									}
									if (v.child && v.child.length) {
										getPermissions(v.child)
									}
								})
							}
							getPermissions(res.data)
							commit('changeUserPermissions', permissions)
							console.log('permissions: ',permissions);
							resolve(res.data)
						},
						err => {
							reject(err)
						}
					)
				}
			})

		},
		// 更新pc端基础信息
		updatePc({
			commit
		}) {
			return new Promise((resolve,reject) => {
				ConfigGroup('pc').then(
					res => {
						commit('changePc', res.data)
						resolve(res.data)
					},
					err => {
						reject(err)
					}
				)
			})

		},
	},
	modules: {}
})
