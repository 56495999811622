<template>
	<div id="app" class="app">
		<div class="backTop" id="backTop"></div>
		<Head v-if="$route.name != 'login'"></Head>
		<!-- 动态判断是否使用 keep-alive -->
		<keep-alive :include="['manage']">
			<router-view />
		</keep-alive>
		<!-- <router-view /> -->
		<Foot v-if="$route.name != 'login' && $route.name != 'orderCreate'"></Foot>
		<div class="back-top" :style="[backTopStyle]" :class="[isVisibility ? 'animate__animated animate__zoomIn' : 'animate__animated animate__zoomOut']" @click="backTop">
			<div class="bt-img"></div>
		</div>
		<!-- 登录提示 -->
		<PopTip ref="PopTip" :show.sync="showPopTip"></PopTip>
		<!-- 富文本弹窗 -->
		<PopRichText ref="PopRichText" :show.sync="showPopRichText"></PopRichText>
	</div>
</template>

<script>
import Head from './components/Head.vue';
import Foot from './components/Foot.vue';

export default {
	components: {
		Head,
		Foot
	},
	data() {
		return {
			showPopTip: false,
			showPopRichText: false,
			backTopStyle: {},
			isVisibility: false,
			scrollTop: ''
		};
	},
	created() {
		// 更新pc端基础信息
		this.$store.dispatch('updatePc');
		console.log('this.$route: ', this.$route.name);
		// 更新用户信息
		if (this.$route.name != 'login') {
			this.$store.dispatch('updateUserInfo');
		}

		// 登录拦截
		this.$bus.$on('login', (data) => {
			console.log('数据', data);
			this.$refs.PopTip.open({
				content: '未登录，是否立即登录？',
				confirm: () => {
					this.$router.push({
						name: 'login',
						query: {
							type: 1
						}
					});
				}
			});
		});

		window.addEventListener('resize', () => {
			this.getBackTop();
		});

		window.addEventListener('scroll', (e) => {
			if (this.$store.state.isElImageViewerWrapperShow) {
				// 当预览图片时，阻止滚动条滚动
				window.scrollTo(0, this.scrollTop);
			} else {
				// 返回顶部
				this.getBackTop();
			}
		});
	},
	mounted() {
		let backTop = document.getElementById('backTop');
		this.$bus.$on('backTop', (data) => {
			backTop.scrollIntoView();
		});
	},
	methods: {
		// 返回顶部动画
		backTop() {
			var duration = 500;
			var millisecond = 10;
			var height = ~parseInt(document.documentElement.scrollTop / (duration / millisecond));
			var timer = setInterval(function () {
				if (document.documentElement.scrollTop > 0) {
					window.scrollBy(0, height);
				} else {
					clearInterval(timer);
				}
			}, millisecond);
		},
		getBackTop() {
			// console.log('window.scrollY: ', window.scrollY);

			let appEl = document.getElementById('app');

			var timeoutInt; // 要保证最后要运行一次

			// setTimeout(function() {
			if (timeoutInt != undefined) {
				window.clearTimeout(timeoutInt);
			}

			timeoutInt = window.setTimeout(() => {
				this.scrollTop = getDocumentTop();
				// console.log(getDocumentTop() + getWindowHeight());
				// console.log(getScrollHeight());
				// 监听事件内容
				if (getScrollHeight() == getDocumentTop() + getWindowHeight()) {
					//当滚动条到底时,这里是触发内容
					//异步请求数据,局部刷新dom
					// ajax_function(); //调用上面自定义的事件函数。
					console.log('滚动到底部了');
					this.$bus.$emit('onReachBottom', '123');
				}
			});
			// }, 100);
			if (window.scrollY > window.screen.height / 2) {
				this.isVisibility = true;
			} else {
				this.isVisibility = false;
				return;
			}
			// console.log('this.isVisibility: ',this.isVisibility);
			// if (!this.isMobile) {
			let gap = window.screen.width - 1200 - 80 * 2;
			// console.log('appEl.offsetWidth: ',appEl.offsetWidth);
			if (appEl.offsetWidth > 1220) {
				let gap = appEl.offsetWidth - 1220 - 50 * 2;
				if (gap > 0) {
					// console.log('gap: ',gap);
					this.backTopStyle = {
						right: gap / 2 - 20 + 'px'
					};
				} else {
					this.backTopStyle = {
						right: 20 + 'px'
					};
				}
			} else {
				this.backTopStyle = {
					right: 20 + 'px'
				};
				// this.backTopClass = {
				// 	position: 'fixed',
				// 	bottom: '50px',
				// 	zIndex: 9999,
				// 	display: 'block',
				// 	right: '12px',
				// 	width: '80px',
				// 	height: '80px',
				// 	opacity: 1
				// };
			}
			// } else {
			// 	let gap = window.screen.width - 1200 - 40 * 2;
			// 	if (gap > 0) {
			// 		this.backTopStyle = {
			// 			position: 'fixed',
			// 			bottom: '180px',
			// 			zIndex: 9999,
			// 			display: 'block',
			// 			right: (gap / 2 - 40) / 2 + 'px',
			// 			width: '40px',
			// 			height: '40px',
			// 			opacity: 1
			// 		};
			// 	} else {
			// 		this.backTopStyle = {
			// 			position: 'fixed',
			// 			bottom: '180px',
			// 			zIndex: 9999,
			// 			display: 'block',
			// 			right: '12px',
			// 			width: '40px',
			// 			height: '40px',
			// 			opacity: 1
			// 		};
			// 	}
			// }

			//（浏览器窗口上边界内容高度）
			function getDocumentTop() {
				var scrollTop = 0,
					bodyScrollTop = 0,
					documentScrollTop = 0;
				if (document.body) {
					bodyScrollTop = document.body.scrollTop;
				}
				if (document.documentElement) {
					documentScrollTop = document.documentElement.scrollTop;
				}
				scrollTop = bodyScrollTop - documentScrollTop > 0 ? bodyScrollTop : documentScrollTop;
				// console.log('scrollTop:' + scrollTop);
				return scrollTop;
			} //可视窗口高度（屏幕可以看见的高度）

			function getWindowHeight() {
				var windowHeight = 0;
				if (document.compatMode == 'CSS1Compat') {
					windowHeight = document.documentElement.clientHeight;
				} else {
					windowHeight = document.body.clientHeight;
				}
				// console.log('windowHeight:' + windowHeight);
				return windowHeight;
			} //滚动条滚动高度（即整个网页的高度）

			function getScrollHeight() {
				var scrollHeight = 0,
					bodyScrollHeight = 0,
					documentScrollHeight = 0;
				if (document.body) {
					bodyScrollHeight = document.body.scrollHeight;
				}
				if (document.documentElement) {
					documentScrollHeight = document.documentElement.scrollHeight;
				}
				scrollHeight = bodyScrollHeight - documentScrollHeight > 0 ? bodyScrollHeight : documentScrollHeight;
				// console.log('scrollHeight:' + scrollHeight);
				return scrollHeight;
			}
		}
	}
};
</script>

<style lang="scss">
@import url('common/common.scss');

#app {
	width: 100%;
	height: auto;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	background-color: #f5f5f5;
}

.back-top {
	position: fixed;
	bottom: 150px;
	z-index: 9000;
	display: block;
	margin-top: 80vh;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	// background-image: url('./assets/087.png');
	// background-image: url('./assets/084.png');
	box-sizing: border-box;
	padding: 10px;
	// background-position: 10px 10px;
	// background-size: 30px 30px;
	opacity: 1;
	background-color: #ffffff;
	box-shadow: 0 0.010417rem 0.0625rem 0 rgba(0, 0, 0, 0.1);
	.bt-img {
		display: block;
		width: 100%;
		height: 100%;
		// border-radius: 50%;
		background-image: url('./assets/087.png');
		// background-image: url('./assets/084.png');
		background-size: 100% 100%;
		opacity: 1;
	}
}

.el-dialog__wrapper {
	display: flex;
	justify-content: center;
	align-items: center;

	.el-dialog {
		margin: 0 !important;
		background-color: transparent;
		width: auto;

		.el-dialog__header {
			display: none;
		}

		.el-dialog__body {
			padding: 0;
		}

		.el-dialog__footer {
			display: none;
		}
	}
}
</style>
