<template>
	<div class="index L">
		<div class="index-content M">
			<div class="content-left">
				<div class="dingdan-tongji">
					<div class="dt-header">
						<div class="dth-headingbox">
							<div class="dthh-text">订单统计</div>
							<div class="dthh-line"></div>
						</div>
						<div class="dth-total">
							累计发布
							<span class="highlight">{{orderStatistics.total || 0}}条</span>
							工单
						</div>
						<div class="dth-all-btn btn-fill" @click="hasPermi('app:order:list', {toast: true}) && toOrder(-1)">全部订单></div>
					</div>
					<div class="dt-body">
						<div class="dtb-item" @click="hasPermi('app:order:list', {toast: true}) && toOrder(100)">
							<div class="dtbi-num">{{orderStatistics.obligations || 0}}</div>
							<div class="dtbi-label">待付款</div>
						</div>
						<div class="dtb-item" @click="hasPermi('app:order:list', {toast: true}) && toOrder(200)">
							<div class="dtbi-num">{{orderStatistics.pendingOrder || 0}}</div>
							<div class="dtbi-label">待接单</div>
						</div>
						<div class="dtb-item" >
							<div class="dtbi-num">{{orderStatistics.serviced || 0}}</div>
							<div class="dtbi-label">待服务</div>
						</div>
						<div class="dtb-item" >
							<div class="dtbi-num">{{orderStatistics.settled || 0}}</div>
							<div class="dtbi-label">待结算</div>
						</div>
						<div class="dtb-item" @click="hasPermi('app:order:list', {toast: true}) && toOrder(700)">
							<div class="dtbi-num">{{orderStatistics.completed || 0}}</div>
							<div class="dtbi-label">已完成</div>
						</div>
						<!-- <div class="dtb-item" >
							<div class="dtbi-num">{{orderStatistics.abnormal || 0}}</div>
							<div class="dtbi-label">异常订单</div>
						</div> -->
						<div class="dtb-item" @click="hasPermi('app:order:list', {toast: true}) && toOrder(900)">
							<div class="dtbi-num">{{orderStatistics.afterSale || 0}}</div>
							<div class="dtbi-label">售后</div>
						</div>
					</div>
				</div>
				<div class="kuaisu-xiadan" v-loading="isLoading">
					<div class="kx-header">
						<div class="kxh-headingbox">
							<div class="kxhh-text">快速下单</div>
							<div class="kxhh-line"></div>
						</div>
					</div>
					<div class="kx-body"  >
						<div  v-for="(quicklyOrderitem,quicklyOrderindex) in quicklyOrder" 
						:key="`quicklyOrder-${quicklyOrderindex}`" @click="hasPermi('app:order:add', {toast: true}) && toOrderCreate(quicklyOrderitem)">
							<el-popover placement="top" width="" trigger="hover">
								<div class="kxbi-popover-content">
									<div class="kxbip-label">{{`${quicklyOrderitem.categoryName}(${quicklyOrderitem.modelName})`}}</div>
									<div class="kxbip-num">{{quicklyOrderitem.omsOrder.serviceType | orderServiceTypeFilter}}X{{quicklyOrderitem.number}}</div>
								</div>
								<div class="kxb-item pointer" slot="reference">
									<div class="kxbi-picture-wrap">
										<el-image :src="imgUrl(quicklyOrderitem.productPicture)" alt="" fit="cover" class="kxbi-picture " />
									</div>
									<div class="kxbi-label txt-ellipsis-2">{{quicklyOrderitem.categoryName}}</div>
									<!-- <div class="kxbi-close"></div> -->
								</div>
							</el-popover>
						</div>
					</div>
					<div class="kx-footer">
						<div class="kxf-xiadan-btn btn-fill" @click="hasPermi('app:order:add', {toast: true}) && toOrderCreate()">立即下单找师傅</div>
						<div class="kxf-description">
							<span class="highlight">{{pc.zhuanyeshifu}}万+</span>
							专业师傅，覆盖全国
							<span class="highlight">{{pc.zhuanyeshifufugailv}}%</span>
							的区域到村镇，快速上门!
						</div>
					</div>
				</div>
			</div>
			<div class="content-right">
				<div class="pingtai-gonggao">
					<div class="pg-header">
						<div class="pgh-headingbox">
							<div class="pghh-text">平台公告</div>
							<div class="pghh-line"></div>
						</div>
					</div>
					<div class="pg-body pointer" v-if="noticeList.length" @click="openPopRichText(noticeList[0])">
						<el-image :src="imgUrl(noticeList[0].noticeImg)" alt="" fit="cover" class="pgb-picture" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		OrderStatistics,
		QuicklyOrder,
		NoticeList
	} from '../common/api.js'
	export default {
		data() {
			return {
				orderStatistics: {},
				quicklyOrder: [],
				noticeList: [],
				isLoading: true,

			}
		},
		created() {
			this.getOrderStatistics()
			this.getQuicklyOrder()
			this.getNoticeList()
		},
		mounted() {
			
		},
		methods: {
			toOrder(status) {
				this.$router.push({ 
					name: 'order',
					params: {
						status: status
					},
				})
			},
			toOrderCreate(data) {
				if (data) {
					this.$store.commit('changeQuicklyOrder',data)
					this.$router.push({ name: 'orderCreate', query: { type: 'quicklyOrder' } })
				} else {
					this.$router.push({ name: 'orderCreate'})
				}
				
			},
			getOrderStatistics() {
				OrderStatistics().then(res => {
					this.orderStatistics = res.data
				})
			},
			getQuicklyOrder() {
				let data = {
					pageSize: 14,
					pageNum: 1
				}
				this.isLoading = true
				QuicklyOrder(data).then(res => {
					this.quicklyOrder = res.rows
				}).finally(() => {
					this.isLoading = false
				})
			},
			getNoticeList() {
				NoticeList().then(res => {
					this.noticeList = res.rows
				})
			},
			openPopRichText(notice) {
				this.$rootApp.$refs.PopRichText.open({
					...notice
				})
			},

		}
	}
</script>

<style>
	#app {
		background-color: #F5F5F5;
	}
</style>
<style lang="scss" scoped>
	.kxbi-popover-content {
		max-width: 970px;
		// padding: 13px;
		display: flex;
		flex-direction: column;
		align-items: center;
		.kxbip-label {
			// white-space: nowrap;
			font-size: 12px;
			font-family: Microsoft YaHei, Microsoft YaHei;
			font-weight: 400;
			color: #333333;
		}
		.kxbip-num {
			// white-space: nowrap;
			font-size: 12px;
			font-family: Microsoft YaHei, Microsoft YaHei;
			font-weight: 400;
			color: #999999;
			margin-top: 8px;
		}
	}
	
	.index {
		.index-content {
			display: flex;
			justify-content: space-between;
			padding: 20px 0 100px 0;
			.content-left {
				display: flex;
				flex-direction: column;
				width: 970px;
				.dingdan-tongji {
					background-color: #FFFFFF;
					.dt-header {
						display: flex;
						align-items: center;
						height: 56px;
						padding-left: 20px;
						border-bottom: 1px solid #EDEDED;
						.dth-headingbox {
							position: relative;
							.dthh-text {
								position: relative;
								z-index: 2;
								font-size: 16px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: bold;
								color: #333333;
								line-height: 16px;
							}
							.dthh-line {
								position: absolute;
								left: 0;
								bottom: -3px;
								width: 100%;
								height: 7px;
								background: linear-gradient(90deg, #5FB14D 0%, rgba(95,177,77,0) 100%);
								border-radius: 0px 0px 0px 0px;
							}
						}
						.dth-total {
							margin: 0 10px;
							font-size: 14px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #333333;
							line-height: 14px;
							.highlight {
								color: #FF5A13;
							}
						}
						.dth-all-btn {
							// width: 86px;
							font-size: 16px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #3571DA;
							line-height: 16px;
							margin-right: 20px;
							margin-left: auto;
						}
					}
					.dt-body {
						height: 104px;
						display: flex;
						box-sizing: border-box;
						padding-top: 26px;
						.dtb-item {
							display: flex;
							flex-direction: column;
							align-items: center;
							width: calc(100% / 6);
							cursor: pointer;
							.dtbi-num {
								font-size: 18px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: bold;
								color: #333333;
								line-height: 18px;
							}
							.dtbi-label {
								margin-top: 20px;
								font-size: 14px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #999999;
								line-height: 14px;
							}
						}
					}
				}
				.kuaisu-xiadan {
					background-color: #FFFFFF;
					margin-top: 20px;
					.kx-header {
						display: flex;
						align-items: center;
						height: 56px;
						padding-left: 20px;
						border-bottom: 1px solid #EDEDED;
						.kxh-headingbox {
							position: relative;
							.kxhh-text {
								position: relative;
								z-index: 2;
								font-size: 16px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: bold;
								color: #333333;
								line-height: 16px;
							}
							.kxhh-line {
								position: absolute;
								left: 0;
								bottom: -3px;
								width: 100%;
								height: 7px;
								background: linear-gradient(90deg, #5FB14D 0%, rgba(95,177,77,0) 100%);
								border-radius: 0px 0px 0px 0px;
							}
						}
					}
					.kx-body {
						box-sizing: border-box;
						padding-left: 18px;
						padding-top: 20px;
						display: flex;
						flex-wrap: wrap;
						
						.kxbi-popover {
							display: none;
							// position: absolute;
							// bottom: calc(100% + 10px);
							// display: flex;
							flex-direction: column;
							align-items: center;
							background-color: #FFFFFF;
							max-width: 80vw;
							// z-index: 99;
							
							
							.kxbip-arrow {
							  width: 17px;
							  height: 17px;
							  border: 1px solid #D9D9D9;
							  border-color: transparent transparent #D9D9D9 #D9D9D9;
							  transform: rotate(-45deg);
							  background-color: #FFFFFF;
							}
						}
						
						.kxb-item {
							margin-right: 24px;
							margin-bottom: 20px;
							width: 112px;
							height: 100px;
							display: flex;
							flex-direction: column;
							align-items: center;
							box-sizing: border-box;
							padding: 5px 10px 0;
							position: relative;
							border: 1px solid #EDEDED;
							.kxbi-picture-wrap {
								width: 90px;
								height: 60px;
								border-radius: 4px 4px 4px 4px;
								margin-top: 5px;
								display: flex;
								justify-content: center;
								align-items: center;
								.kxbi-picture {
									width: 90px;
									height: 60px;
									object-fit: contain;
								}
							}
							.kxbi-label {
								font-size: 14px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: 400;
								color: #333333;
								line-height: 14px;
								margin: auto;
							}
							.kxbi-close {
								display: none;
								width: 20px;
								height: 20px;
								background-image: url('../assets/015.png');
								background-size: 100% 100%;
								position: absolute;
								top: 0;
								right: 0;
								transform: translate(50%, -50%);
							}
							
							&:hover {
								// border-color: #3571DA;
								// box-shadow: 0 0 8px #3571DA;
								transform: scale(1.05);
								box-shadow: 0 0.010417rem 0.0625rem 0 rgba(0,0,0,.1);
								.kxbi-close {
									display: initial;
								}
								.kxbi-popover {
									display: flex;
									.kxbip-label {}
									.kxbip-num {}
								}
							}
						}
					}
					.kx-footer {
						margin-top: 20px;
						display: flex;
						flex-direction: column;
						align-items: center;
						.kxf-xiadan-btn {
							width: 207px;
							height: 48px;
							font-size: 18px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: bold;
							color: #FFFFFF;
							line-height: 48px;
							text-align: center;
							background-color: #3571DA;
						}
						.kxf-description {
							margin-top: 20px;
							font-size: 14px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: bold;
							color: #666666;
							line-height: 16px;
							margin-bottom: 48px;
							.highlight {
								color: #3571DA;
							}
						}
					}
				}
			}
			.content-right {
				width: 230px;
				.pingtai-gonggao {
					background-color: #FFFFFF;
					.pg-header {
						display: flex;
						align-items: center;
						height: 56px;
						padding-left: 20px;
						// border-bottom: 1px solid #EDEDED;
						.pgh-headingbox {
							position: relative;
							.pghh-text {
								position: relative;
								z-index: 2;
								font-size: 16px;
								font-family: Microsoft YaHei, Microsoft YaHei;
								font-weight: bold;
								color: #333333;
								line-height: 16px;
							}
							.pghh-line {
								position: absolute;
								left: 0;
								bottom: -3px;
								width: 100%;
								height: 7px;
								background: linear-gradient(90deg, #5FB14D 0%, rgba(95,177,77,0) 100%);
								border-radius: 0px 0px 0px 0px;
							}
						}
					}
					.pg-body {
						display: flex;
						justify-content: center;
						.pgb-picture {
							width: 190px;
							height: 120px;
							margin: 0 0 24px;
						}
					}
				}
			}
		}
	}
</style>