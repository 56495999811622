import config from "../common/config.js"
import store from '../store/index.js'
import {
	mapState,
	mapGetters
} from 'vuex'
import {
	imgUrl,
	starFilter,
	downloadFile
} from '../common/h5.js'
import {
	filesDownloadZip
} from '../common/request.js'
import debounce from 'lodash/debounce'

export default {
	data() {
		return {
			webImg: config.imgRoot, //网络图片前缀
			_lastTime: null, //上次点击时间
			isOffLine: false, //是否断网了
			videoFileType: ['mp4', 'avi', 'wmv'],
			pageSizesOptions: [10, 20, 30, 50, 100],

		};
	},
	computed: {
		...mapState(['userInfo', 'pc']),
		...mapGetters(['isLogin', 'isSubAccount'])
	},
	updated() {
		// 当预览图片时，阻止滚动条滚动
		this.handleIsElImageViewerWrapperShowDebounce()
	},
	filters: {
		// 服务项目
		orderServiceTypeFilter(v) {
			// 服务项目 1=安装2=维修3=清洗4=调试
			if (v) {
				if (v == 1) {
					return '安装'
				} else if (v == 2) {
					return '维修'
				} else if (v == 3) {
					return '清洗'
				} else if (v == 4) {
					return '调试'
				} else if (v == 5) {
					return '回收'
				}
			} else {
				return '无'
			}

		},
		// 订单节点
		orderNodeFilter(v) {
			// console.log('orderNodeFilter: ', v);
			// 订单节点 支付成功 210 指派师傅 220 指派师傅确认 230 指派师傅拒绝 240 师傅接单 310 预约上门 410 修改时间 420 打卡成功 510 安装材料提交 520 待配件 530 配件已发货 540 配件已到达 550 完工申请 560 保外付款成功 572 评价完成 670 订单完成 710 返件待申核 630 返件申请驳回 634 促好评待审核 650 返件申核通过 640 促评申核通过 660 促评申核驳回 664 已关闭 810 待审核 910 同意售后920(售后指派师傅确认 920220 售后指派师傅确认 920230 售后指派师傅拒绝 920240 售后预约上门 920410 售后修改时间 920420 售后打卡成功 920510 售后安装材料提交 920520 售后待配件 920530 售后配件已发货 920540 售后配件已到达 920550 售后完工申请 920560 售后保外付款成功 920572 售后返件待申核 920630 售后返件申核通过 920640) 售后申请驳回 930 售后完成 940
			if (v) {
				let result = store.state.orderNodeList.find(e => e.value == v)
				if (result) {
					return result.label
				} else {
					return ''
				}
			} else {
				return '无'
			}

		},
		// 订单状态
		orderStatusFilter(v, keyName = 'label') {
			// console.log('orderStatusFilter: ', v);
			// 订单状态 待付款100,待接单200,待预约300,待施工400,施工中500,待验收600,已完成700,已关闭800,售后900
			if (v) {
				let result = store.state.orderStatusList.find(e => e.value == v)
				if (result) {
					return result[keyName]
				} else {
					return ''
				}
			} else {
				if (keyName == 'label') {
					return '无'
				} else if (keyName == 'color') {
					return ''
				}
			}
		},
		// 转虚拟号
		phoneExtensionFilter(v) {
			return v ? `转${v}` : ''
		}
	},
	methods: {
		handleIsElImageViewerWrapperShowDebounce: debounce(function() {
			let elImageViewerWrapper = document.getElementsByClassName('el-image-viewer__wrapper')
			if (elImageViewerWrapper && elImageViewerWrapper.length) {
				store.commit('changeIsElImageViewerWrapperShow', true)
			} else {
				store.commit('changeIsElImageViewerWrapperShow', false)
			}
		}, 500),
		navigateBack(delta = -1) {
			this.$router.go(delta)
		},
		//检测有无token
		checkUser() {
			console.log('localStorage.getItem("token"): ', localStorage.getItem("token"));
			if (!localStorage.getItem("token")) {
				return false
			}
			return true;
		},
		handleVisiable(e) {
			let isExist = e.target.visibilityState
			console.log(isExist)
			if (isExist === 'visible') {
				this.mathRdm()
				console.log(new Date().toLocaleString(), `您已进入页面！`)
			} else {
				clearInterval(this.timer)
				console.log(new Date().toLocaleString(), `您已离开页面！`)
			}
			switch (e.target.visibilityState) {
				case 'prerender':
					console.log('网页预渲染，内容不可见')
					break;
				case 'hidden':
					console.log('内容不可见，处理后台、最小化、锁屏状态')
					break;
				case 'visible':
					console.log('处于正常打开')
					break;
			}
		},
		mathRdm() {
			let num = 5000
			this.timer = setInterval(() => {

			}, num)
		},
		/**
		 * 数字字段值转换 规避toFixed 精度丢失问题
		 * @param {输入的数字 string、int 类型均可} n
		 * @param {保留位数 可输入 0} d
		 * @returns
		 */
		toFixedNum(n, d) {
			// 判断是小数
			var isFloat = parseInt(n) < parseFloat(n);

			if (typeof(d) == "undefined") {
				// 获取浮点型数值的小数位数
				d = (n != Math.floor(n)) ? (n.toString()).split('.')[1].length : 0;
			}

			if (isFloat) {
				n = Number(n);
				d = Number(d);
				n = n * Math.pow(10, d);
				n = Math.round(n);
				n = Number(n);
				n = n / Math.pow(10, d);
				n = n.toFixed(d);
				n = Number(n);
			} else {
				n = Number(n);
			}

			return n;
		},
		throttle(fn, gapTime) {
			if (gapTime == null || gapTime == undefined) {
				gapTime = 500 // 默认500毫秒
			}
			let _nowTime = +new Date()
			if (_nowTime - this._lastTime > gapTime || !this._lastTime) {
				fn()
				// this.$message.success('刷新成功')
				this._lastTime = _nowTime
			}
		},
		//检测有无token
		checkUser() {
			console.log('localStorage.getItem("token"): ', localStorage.getItem("token"));
			if (!localStorage.getItem("token")) {
				return false
			}
			return true;
		},
		// 返回
		goBack(delta = -1) {
			window.history.length > 1 ? this.$router.go(delta) : this.$router.push('/')
		},
		// 消息
		toast(Options) {
			if (typeof Options === "string") {
				this.$message({
					message: Options,
					type: 'warning',
				});
			} else {
				this.$message({
					message: '',
					type: '',
					customClass: '',
					...Options
				});
			}

		},
		MHome() {
			this.$router.push('/home')
		},
		openHome() {
			this.$router.push({
				name: 'index'
			})
		},
		toLink(path) {
			this.$router.push(path)
		},
		// 图片路径
		imgUrl(url, showDefault, defaultUrl) {
			return imgUrl(url, showDefault, defaultUrl)
		},
		// 文件下载
		downloadFile(data) {
			return downloadFile(...arguments)
		},
		// 视频封面路径
		videoUrl(url, showDefault, defaultUrl) {
			return imgUrl(url, showDefault, defaultUrl) +
				'?x-oss-process=video/snapshot,t_7000,f_jpg,w_800,h_600,m_fast'
		},
		// 权限判断
		hasPermi(permissions, options = {}) {
			let {
				toast = false
			} = options
			if (store.state.userPermissions.some(v => v == "*:*:*")) {
				return true
			} else {
				if (!permissions) {
					return true
				} else {
					if (!Array.isArray(permissions)) {
						permissions = permissions.split(',')
					}
					// console.log('permissions: ', permissions);
					for (var i = 0; i < permissions.length; i++) {
						if (!store.state.userPermissions.some(v => v == permissions[i])) {
							if (toast) {
								this.toast({
									message: '没有权限',
									type: 'info'
								})
							}
							return false
						}
					}
					return true
				}
			}
		},

	},
}