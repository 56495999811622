<template>
	<div>
		<el-dialog :visible.sync="dialogVisible" :lock-scroll="false">
			<div class="wangongcailiao">
				<div class="pop-header">
					<div class="ph-title">完工材料</div>
					<div class="ph-close" @click="dialogVisible = false"></div>
				</div>
				<div class="pop-body" >
					<template v-if="detail.omsOrder && detail.omsOrder.serviceType == 5">
						<div class="card">
							<div class="pb-text-1">完工照片</div>
							<div class="li-album">
								<Media :value="detail.recycleImgs"></Media>
							</div>
						</div>
					</template>
					<template v-else-if="detail.omsOrderProductList">
						<div class="card" v-for="(item,index) in detail.omsOrderProductList" :key="index">
							<div class="pb-text-1">完工照片</div>
							<div class="li-album">
								<Media :value="item.picUrls" keyName="fileUrl"></Media>
							</div>
							<div class="pb-text-1">试机视频</div>
							<div class="li-album">
								<Media :value="item.videoUrls" keyName="fileUrl"></Media>
							</div>
						</div>
						<div class="card">
							<div class="pb-text-1">辅材明细</div>
							<template>
								<div class="li-album" v-if="detail.auxiliaryMaterial">
									<Media :value="detail.auxiliaryMaterial"></Media>
								</div>
								<div v-else class="pb-empty">无</div>
							</template>
						</div>
					</template>
					<div class="pb-field">
						<div class="pbf-label">备注信息</div>
						<div class="pbf-value">{{detail.remarks || '无'}}</div>
					</div>
				</div>
				<div class="pop-footer">
					<div class="pf-cancel btn-fill" @click="dialogVisible = false">关闭</div>
				</div>
			</div>
		</el-dialog>
		<video-show ref="videoshwo"></video-show>
	</div>
</template>

<script>
	import {
		getApplication
	} from '../common/api.js'
	import VideoShow from "@/components/videoShow";


	export default {
		name: 'PopOrderWangong',
		components: {
			VideoShow
		},
		data() {
			return {
				dialogVisible: false,
				applicationId: '',
				detail: {},
			}
		},

		methods: {
			open(applicationId) {
				this.applicationId = applicationId
				this.loadData()
				this.dialogVisible = true
			},
			close() {
				this.dialogVisible = false
			},
			loadData() {
				getApplication(this.applicationId).then(res => {
					this.detail = res.data
					if (this.detail) {
						this.detail.omsOrderProductList.forEach((v,i) => {
							v.picUrls = [],
							v.videoUrls = []
							
							this.detail.omsOrderProductList[i].omsProductCompletionPicList.forEach(f => {
								if (f.fileType == 1) {
									v.picUrls.push({
										fileType: 'image',
										fileUrl: f.fileUrl
									})
								} else {
									v.videoUrls.push({
										fileType: 'video',
										fileUrl: f.fileUrl
									})
								}
							})
						})
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.wangongcailiao {
		min-width: 600px;
		max-width: 800px;

		// min-height: 396px;
		background: #FFFFFF;
		border-radius: 8px 8px 8px 8px;
		box-sizing: border-box;
		padding: 0 20px;

		.pop-header {
			height: 56px;
			display: flex;
			align-items: center;
			position: relative;
			border-bottom: 1px solid #EDEDED;

			.ph-title {
				font-size: 16px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				line-height: 16px;
			}

			.ph-close {
				width: 16px;
				height: 16px;
				background-image: url('../assets/058.png');
				background-size: 100% 100%;
				position: absolute;
				right: 0;
			}
		}

		.pop-body {
			box-sizing: border-box;
			padding: 0 20px 56px 0;
			display: flex;
			flex-direction: column;
			max-height: 60vh;
			overflow: auto;
			margin-right: -20px;

			.card {
				.pb-text-1 {
					margin: 20px 0;
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
				}
	
				.li-album {
					box-sizing: border-box;
					display: flex;
					flex-wrap: wrap;
	
					.lia-item {
						width: 120px;
						height: 120px;
						margin-right: 20px;
						position: relative;
						border-radius: 4px;
						overflow: hidden;
	
						&:nth-child(4n) {
							margin-right: 0;
						}
	
						.liai-pic {
							width: 120px;
							height: 120px;
						}
					}
				}
				
				.pb-empty {
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #666666;
				}
			}

			.pb-field {
				display: flex;
				margin-top: 20px;

				.pbf-label {
					margin-right: 20px;
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
				}

				.pbf-value {
					font-size: 16px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #666666;
				}
			}
		}

		.pop-footer {
			border-top: 1px solid #EDEDED;
			height: 72px;
			display: flex;
			justify-content: center;
			align-items: center;

			.pf-cancel {
				width: 88px;
				height: 32px;
				border-radius: 4px 4px 4px 4px;
				opacity: 1;
				border: 1px solid #3571DA;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 14px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				color: #3571DA;
				line-height: 14px;
				box-sizing: border-box;
				margin: 0 10px;
			}

			.pf-confirm {
				width: 88px;
				height: 32px;
				background: #3571DA;
				border-radius: 4px 4px 4px 4px;
				font-size: 14px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 32px;
				text-align: center;
				margin: 0 10px;
			}
		}
	}
</style>