<template>
	<div class="order-component">
		<el-dialog :visible.sync="dialogVisible" :show-close="false" :lock-scroll="false" :modal="false" @closed="onClosed">
			<div class="order-dialog">
				<div class="dialog-header">
					<!-- <div class="title">提示</div> -->
					<div class="ph-close pointer" @click="close"></div>
				</div>
				<div class="dialog-body">
					<div class="order">
						<div class="cover">
							<img :src="imgUrl(options.tmporderThumb)" alt="" />
						</div>
						<div class="content">
							<div class="title">{{ options.tmpTitle }}</div>
							<div class="num">商品数量：{{ options.tmpordernum }}</div>
							<div class="serial">订单编号：{{ options.tmporderid }}</div>
						</div>
					</div>
				</div>
				<div class="dialog-footer">
					<el-button type="primary" class="btn-confirm" @click="confirm">发送</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
export default {
	props: ['options'],
	mounted() {
		// setTimeout(() => {
		// 	this.close();
		// }, 5000);
		this.$bus.$on('XKFOrderClose', this.close);
	},
	beforeDestroy() {
		this.$bus.$off('XKFOrderClose', this.close);
	},
	data() {
		return {
			dialogVisible: true
		};
	},
	watch: {
		dialogVisible: {
			handler: function (val) {
				this.$emit('update:show', val);
			}
		}
	},
	methods: {
		open(obj = {}) {
			this.dialogVisible = true;
		},
		close(obj = {}) {
			this.dialogVisible = false;
		},
		onClosed() {
			// this.$el.removeEventListener('transitionend', this.destroyElement);
			this.$destroy(true);
			this.$el.parentNode.removeChild(this.$el);
		},
		confirm() {
			if (this.options.confirm) {
				this.options.confirm();
			}
			this.close();
		}
	}
};
</script>

<style lang="scss" scoped>
// 修改自带样式
.dialog-fade-enter-active {
	animation: none !important;
}
.dialog-fade-leave-active {
	animation: none !important;
}
::v-deep .el-dialog__wrapper {
	display: flex;
	align-items: flex-start;
	top: initial;
	/* prettier-ignore */
	right: calc(32PX + 20PX);
	/* prettier-ignore */
	bottom: 200PX;
	z-index: 10000 !important;
	left: initial;

	.el-dialog {
		width: auto;
		background-color: transparent;

		.el-dialog__header {
			padding: 0;
		}
		.el-dialog__body {
			padding: 0;
			transition: none !important;
		}
		.el-dialog__footer {
			padding: 0;
		}
	}
}
.order-component {
	.order-dialog {
		/* prettier-ignore */
		width: 373PX;
		// height: 420px;
		background: #ffffff;
		border-radius: 8px;
		display: flex;
		flex-direction: column;
		box-sizing: border-box;
		padding: 0 14px 0 17px;
		position: relative;
		.dialog-header {
			/* prettier-ignore */
			padding: 10PX 0;
			.title {
				font-family: PingFangSC, PingFang SC;
				font-weight: 500;
				font-size: 18px;
				color: #000000;
				line-height: 25px;
			}
			.ph-close {
				/* prettier-ignore */
				width: 16PX;
				/* prettier-ignore */
				height: 16PX;
				background-image: url('../assets/058.png');
				background-size: 100% 100%;
				position: absolute;
				/* prettier-ignore */
				right: 10PX;
			}
		}
		.dialog-body {
			.order {
				display: flex;
				.cover {
					/* prettier-ignore */
					margin-right: 10PX;
					img {
						/* prettier-ignore */
						width: 90PX;
						/* prettier-ignore */
						height: 90PX;
						/* prettier-ignore */
						border-radius: 4PX;
					}
				}
				.content {
					flex: 1;
					display: flex;
					flex-direction: column;
					.title {
						font-family: PingFangSC, PingFang SC;
						font-weight: 500;
						/* prettier-ignore */
						font-size: 18PX;
						color: #000000;
						/* prettier-ignore */
						line-height: 25PX;
					}
					.num {
						font-family: PingFangSC, PingFang SC;
						/* prettier-ignore */
						font-size: 14PX;
						color: #999;
						/* prettier-ignore */
						line-height: 25PX;
					}
					.serial {
						font-family: PingFangSC, PingFang SC;
						/* prettier-ignore */
						font-size: 14PX;
						color: #999;
						/* prettier-ignore */
						line-height: 25PX;
					}
				}
			}
		}
		.dialog-footer {
			display: flex;
			/* prettier-ignore */
			margin-top: 10PX;
			/* prettier-ignore */
			margin-bottom: 20PX;
			.btn-confirm {
				/* prettier-ignore */
				margin-left: auto;
				/* prettier-ignore */
				margin-right: 0;
			}
			.el-button {
				/* prettier-ignore */
				width: 72px;
				/* prettier-ignore */
				height: 30px;
				padding: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				/* prettier-ignore */
				border-radius: 2px;
				font-family: PingFangSC, PingFang SC;
				font-weight: 400;
				/* prettier-ignore */
				font-size: 12px;
			}
		}
	}
}
</style>
