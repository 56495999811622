<template>
	<div class="order">
		<div class="card">
			<div class="c-header">
				<div class="ch-left">
					<div class="ch-headingbox">
						<div class="chh-text">全部订单</div>
						<div class="chh-line"></div>
					</div>
					<div class="chl-total">
						共
						<span class="highlight">{{ orderDealtWithOrder || 0 }}项</span>
						待处理订单
						<span class="blue" @click="checkWait">查看></span>
					</div>
				</div>
				<div class="ch-right">
					<div class="chr-input-box">
						<el-input v-model="form.keyWords" class="input input-1" placeholder="客户名字/手机号/订单编号/产品名称/备注说明" clearable></el-input>
					</div>
					<div class="chr-search-btn btn-fill" @click="filter">
						<div class="chrs-icon"></div>
						<div class="chrs-text">搜索</div>
					</div>
				</div>
			</div>
			<div class="c-body">
				<div class="filter">
					<div class="filter-row">
						<div class="fr-label">订单状态：</div>
						<div class="fr-content">
							<div class="" v-for="(item, index) in orderStatusList" :key="`orderStatusList-${index}`" @click="filterItemClick('status', item, index)">
								<div class="frc-item pointer" v-if="!item.value" :class="{ active: item.checked }">
									<div class="mul-checkbox btn-fill">
										<div class="checkbox">
											<div class="no" v-if="!item.checked"></div>
											<div class="yes" v-else></div>
										</div>
										<div class="label">{{ item.label }}</div>
									</div>
								</div>
								<div class="frc-item pointer" v-else :class="{ active: item.checked }">{{ item.label }}</div>
							</div>
						</div>
					</div>
					<div class="filter-row">
						<div class="fr-label">订单节点：</div>
						<div class="fr-content">
							<div class="" v-for="(item, index) in orderNodeList" :key="`orderNodeList-${index}`" @click="filterItemClick('node', item, index)">
								<div class="frc-item pointer" v-if="!item.value" :class="{ active: item.checked }">
									<div class="mul-checkbox btn-fill">
										<div class="checkbox">
											<div class="no" v-if="!item.checked"></div>
											<div class="yes" v-else></div>
										</div>
										<div class="label">{{ item.label }}</div>
									</div>
								</div>
								<div class="frc-item pointer" v-else :class="{ active: item.checked }">{{ item.label }}</div>
							</div>
						</div>
					</div>
					<div class="filter-row">
						<div class="fr-label">订单标签：</div>
						<div class="fr-content">
							<div
								class="frc-item pointer"
								:class="{ active: form.label == item.value }"
								v-for="(item, index) in orderLabelList"
								:key="`orderLabelList-${index}`"
								@click="filterItemClick('label', item, index)"
							>
								{{ item.label }}
							</div>
						</div>
					</div>
					<div class="filter-row">
						<div class="fr-label">订单时间：</div>
						<div class="fr-content">
							<div
								class="frc-item pointer"
								:class="{ active: form.date == item.value }"
								v-for="(item, index) in orderDateList"
								:key="`orderDateList-${index}`"
								@click="filterItemClick('date', item, index)"
							>
								{{ item.label }}
							</div>
						</div>
					</div>
				</div>
				<div class="cb-row">
					<div class="cbr-input-box">
						<div class="cbri-label">下单日期：</div>
						<el-date-picker
							v-model="form.createTime"
							class="date type-1"
							type="daterange"
							value-format="yyyy-MM-dd"
							start-placeholder="开始日期"
							end-placeholder="结束日期"
							:default-time="['00:00:00', '23:59:59']"
							@change="createTimeChange"
						></el-date-picker>
					</div>
					<div class="cbr-input-box">
						<div class="cbri-label">完工时间：</div>
						<el-date-picker
							v-model="form.completionTime"
							class="date type-1"
							type="daterange"
							value-format="yyyy-MM-dd"
							start-placeholder="开始日期"
							end-placeholder="结束日期"
							:default-time="['00:00:00', '23:59:59']"
						></el-date-picker>
					</div>
					<div class="cbr-shaixuan-btn btn-fill" @click="filter">筛选</div>
					<div class="cbr-reset-btn btn-fill" @click="reset">重置</div>
				</div>
				<div class="cb-line"></div>
				<div class="cb-list">
					<div class="cbl-header">
						<template v-if="hasPermi('app:order:cancel')">
							<div class="mul-checkbox btn-fill" @click="checkedAllClick">
								<div class="checkbox">
									<div class="no" v-if="!checkedAll"></div>
									<div class="yes" v-else></div>
								</div>
								<div class="label">全选</div>
							</div>
							<div class="cblh-cancel btn-fill" @click="openOrderCancelForSelect()">批量取消订单</div>
						</template>
						<div class="cbr-add-btn btn-fill" v-if="hasPermi('app:order:export')" @click="handleExport()">
							<div class="cbra-icon"></div>
							<div class="cbra-text">导出</div>
						</div>
					</div>
					<div class="empty-wrap" v-loading="isLoading" v-if="!orderList.length">
						<el-empty :style="!isLoading | visibilityFilter" description="暂无数据"></el-empty>
					</div>
					<div v-loading="isLoading" v-show="orderList.length">
						<div class="list-item" v-for="(orderListitem, orderListindex) in orderList" :key="`orderList-${orderListitem.id}`">
							<div class="li-header">
								<div class="mul-checkbox btn-fill" @click="orderListitemSelect(orderListitem, orderListindex)">
									<div class="checkbox">
										<div class="no" v-if="!orderListitem.checked"></div>
										<div class="yes" v-else></div>
									</div>
									<div class="label">{{ orderListitem.createType == 1 ? '一口价' : '' }}</div>
								</div>
								<div class="lih-line"></div>
								<div class="lih-field">
									<span class="lihf-text">工单号：</span>
									<span class="highlight">{{ orderListitem.orderNo }}</span>
								</div>
								<div class="lih-date">下单时间：{{ $moment(orderListitem.createTime).format('YYYY-MM-DD HH:mm') }}</div>
								<div class="lih-jiaji">{{ orderListitem.parole == 1 ? '保内' : '保外' }}</div>
								<div class="lih-jiaji" v-if="orderListitem.urgentFlag == 1">加急</div>
								<div class="lih-jiaji" v-if="orderListitem.status == 900">售后</div>
								<div class="lih-date green" v-if="orderListitem.completionTime">
									完工时间：{{ $moment(orderListitem.completionTime).format('YYYY-MM-DD HH:mm') }}
								</div>
								<div class="lih-kefu-wrapper">
									<div class="lih-kefu pointer online" @click="openKeFu(orderListitem)">
										<div class="lihk-icon weixin"></div>
										<div class="lihk-text">在线客服</div>
									</div>
									<el-popover placement="top-start" width="" trigger="hover">
										<div class="kefuPhone">
											<div class="fy-item" v-for="(kefuPhoneitem, kefuPhoneindex) in kefuPhone" :key="`kefuPhone-${kefuPhoneindex}`">
												<div class="fyi-label">{{ kefuPhoneitem }}</div>
											</div>
										</div>
										<div class="lih-kefu pointer" slot="reference" @click="openPopCustomerService(orderListitem)">
											<div class="lihk-icon"></div>
											<div class="lihk-text">电话客服</div>
										</div>
									</el-popover>
									<!-- <div class="lih-kefu pointer" v-if="orderListitem.customerServiceId" @click="openPopCustomerService(orderListitem)">
										<div class="lihk-icon"></div>
										<div class="lihk-text">客服</div>
										<div class="lihk-text" v-if="orderListitem.customerServiceUser">
											{{ orderListitem.customerServiceUser.name }}{{ orderListitem.customerServiceUser.phone }}
										</div>
									</div> -->
								</div>
							</div>
							<div class="li-table">
								<div class="column column-1">
									<div class="good-item" v-for="(productListitem, productListindex) in orderListitem.productList" :key="`productList-${productListindex}`">
										<div class="gi-pic">
											<Media mediaClass="w92h92g20b4" :value="productListitem.productPicture"></Media>
										</div>
										<div class="gi-content">
											<div class="gic-header">
												<div class="gich-name">
													{{ `${productListitem.categoryName}(${productListitem.modelName})X${productListitem.number}` }}
												</div>
												<!-- <div class="gich-price nowrap">
													￥{{ orderListitem.parole == 1 ? productListitem.price : productListitem.guidePrice }}
												</div> -->
											</div>
											<div class="gic-field">
												服务项目:
												{{ orderListitem.serviceType | orderServiceTypeFilter }}
											</div>
											<div class="gic-field">
												<div class="gich-price nowrap">
													￥
													<template v-if="orderListitem.serviceType == 5">0</template>
													<template v-else>
														{{ orderListitem.parole == 1 ? productListitem.price : productListitem.guidePrice }}
													</template>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="column column-1-1">
									<el-popover
										placement="right-end"
										class="wuliu-popover"
										width=""
										:offset="100"
										:disabled="!orderListitem.cmsLogisticsDetail"
										trigger="hover"
										@show="wuliuPopoverShow(orderListitem)"
									>
										<PopOrderWuliuList :list="wuliulist"></PopOrderWuliuList>
										<div class="wuliu-wrap" slot="reference">
											<div class="price">物流信息</div>
											<div class="wuliu-content" v-c-ellipsis>
												{{ orderListitem.cmsLogisticsDetail && orderListitem.cmsLogisticsDetail.context }}
											</div>
										</div>
									</el-popover>
								</div>
								<div class="column column-2" :id="`address-${orderListindex}`">
									<div class="userName">{{ orderListitem.realName }}({{ orderListitem.phone }}{{ orderListitem.phoneExtension | phoneExtensionFilter }})</div>
									<div class="address">
										{{ `${orderListitem.provinceName}${orderListitem.cityName}${orderListitem.districtName}${orderListitem.detailAddress}` }}
									</div>
									<div class="btns">
										<div
											class="cb-edit pointer"
											v-if="hasPermi('app:order:modify') && orderListitem.status <= 400"
											@click="openPopOrderCustomerModify(orderListitem)"
										></div>
										<div class="cb-copy pointer" @click="copy(orderListitem, orderListindex)"></div>
									</div>
								</div>
								<div class="column column-3">
									<template v-if="orderListitem.serviceType == 5">
										<div class="price">￥0</div>
										<div class="price-detail pointer">费用明细</div>
									</template>
									<template v-else>
										<div class="price">￥{{ orderListitem.totalPrice }}</div>
										<el-popover placement="top-start" width="" trigger="hover">
											<div class="feiyongmingxi">
												<div
													class="fy-item"
													v-for="(omsOrderExpensesitem, omsOrderExpensesindex) in orderListitem.omsOrderExpenses"
													:key="`omsOrderExpenses-${omsOrderExpensesindex}`"
												>
													<div class="fyi-label">{{ omsOrderExpensesitem.name }}</div>
													<div class="fyi-value">
														<template v-if="omsOrderExpensesitem.type == 1">￥{{ omsOrderExpensesitem.price }}</template>
														<template v-if="omsOrderExpensesitem.type == 2">-￥{{ omsOrderExpensesitem.price }}</template>
													</div>
												</div>
											</div>
											<div class="price-detail pointer" slot="reference">费用明细</div>
										</el-popover>
									</template>
								</div>
								<div class="column column-4">
									<div class="jindu">订单进度</div>
									<div class="jindu-item">{{ orderListitem.node | orderNodeFilter }}</div>
								</div>
								<div class="column column-5">
									<div class="status">当前状态</div>
									<div class="status-value" :style="orderListitem.status | backgroundColorFilter('orderStatus')">
										{{ orderListitem.status | orderStatusFilter }}
									</div>
								</div>
								<!-- <div class="column column-5-1">
									<div class="status">跟单日记</div>
									<el-popover placement="top-start" width="" trigger="hover">
										<div class="status-value-hover">{{orderListitem.followRemark || ''}}</div>
										<div class="status-value" slot="reference">{{orderListitem.followRemark || ''}}
										</div>
									</el-popover>
								</div> -->
								<div class="column column-5-1">
									<el-popover placement="top-start" class="riji-popover" width="" :disabled="!orderListitem.followRemark" trigger="hover">
										<div class="riji-popover-content">
											<div class="riji-popover-content-text">
												{{ orderListitem.followRemark || '' }}
											</div>
										</div>
										<div class="riji-wrap" slot="reference">
											<div class="status">跟单日记</div>
											<div class="riji-content" v-c-ellipsis>
												{{ orderListitem.followRemark || '' }}
											</div>
										</div>
									</el-popover>
								</div>
								<div class="column column-6">
									<div class="btn-option type-2 pointer btn-fill" v-if="orderListitem.statusInfo.isPay" @click="toOrderPay(orderListitem)">立即付款</div>
									<div
										class="btn-option type-2 pointer"
										v-if="orderListitem.statusInfo.isApprovalAccessories"
										@click="openWeixiucailiao(orderListitem)"
										@success="loadData()"
									>
										配件发货
									</div>
									<div
										class="btn-option type-2 pointer"
										v-if="orderListitem.statusInfo.isPromotePositive"
										@click="$refs.cuhaoping.open(orderListitem.id)"
										@success="loadData()"
									>
										促好评审批
									</div>
									<div
										class="btn-option type-2 pointer"
										v-if="orderListitem.statusInfo.isFanJian"
										@click="$refs.fanjian.open(orderListitem.id, null, orderListitem)"
										@success="loadData()"
									>
										返件审核
									</div>
									<div
										class="btn-option type-1 pointer"
										v-if="hasPermi('app:order:confirmation') && orderListitem.statusInfo.isCompletionConfirmation"
										@click="setOrderConfirmation(orderListitem)"
									>
										完工确认
									</div>
									<div
										class="btn-option type-1 pointer"
										v-if="hasPermi('app:order:speed') && orderListitem.statusInfo.isUrgent"
										@click="openPopOrderUrgent(orderListitem)"
									>
										加急
									</div>
									<div class="btn-option type-1 pointer" v-if="orderListitem.statusInfo.isReminder" @click="openPopOrderReminder(orderListitem)">催单</div>
									<div class="btn-option type-1 pointer" @click="toOrderDetail(orderListitem)">订单详情</div>
									<div
										class="btn-option type-1 pointer"
										v-if="hasPermi('app:order:cancel') && orderListitem.statusInfo.isCancell"
										@click="openOrderCancel(orderListitem)"
									>
										取消订单
									</div>
									<div
										class="btn-option type-1 pointer"
										v-if="hasPermi('app:order:after') && orderListitem.statusInfo.isAfterSales"
										@click="openPopAfterSalesApply(orderListitem)"
									>
										申请售后
									</div>
									<div class="btn-option type-1 pointer" v-if="hasPermi('app:order:add')" @click="toOrderCreate(orderListitem)">再次下单</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="pagination-wrap">
					<div class="pagination">
						<el-pagination
							background
							:current-page.sync="pageNum"
							:page-size.sync="pageSize"
							:page-sizes="pageSizesOptions"
							layout="sizes, prev, pager, next"
							:total="total"
							@size-change="loadData()"
							@current-change="loadData()"
						></el-pagination>
					</div>
				</div>
			</div>
		</div>

		<!-- 修改客户信息 -->
		<PopOrderCustomerModify ref="PopOrderCustomerModify" :show:sync="showPopOrderCustomerModify" @success="loadData"></PopOrderCustomerModify>
		<!-- 加急 -->
		<PopOrderUrgent ref="PopOrderUrgent" :show:sync="showPopOrderUrgent" @success="loadData"></PopOrderUrgent>
		<!-- 联系客服 -->
		<PopCustomerService ref="PopCustomerService" :show:sync="showPopCustomerService"></PopCustomerService>
		<!-- 订单催单 -->
		<PopOrderReminder ref="PopOrderReminder" :show:sync="showPopOrderReminder" @success="loadData"></PopOrderReminder>
		<!-- 取消订单提示 -->
		<PopTip ref="OrderCancel" :show.sync="showOrderCancel">
			<div class="pop-body OrderCancel" slot="default" slot-scope="{ popData }">
				<div class="pb-icon"></div>
				<div class="pb-content">是否确认取消订单？</div>
				<div class="pb-fields" v-for="(item, index) in popData.orderList" :key="`orderList-${index}`">
					<div class="pbf-item">订单号:{{ item.orderNo }}</div>
					<div class="pbf-item">下单时间: {{ $moment(item.createTime).format('YYYY-MM-DD HH:mm') }}</div>
				</div>
			</div>
		</PopTip>
		<!-- 安装/维修材料 -->
		<WeixuiPop ref="weixiucailiao" @success="loadData('nobackTop')"></WeixuiPop>

		<!-- 返件申请 -->
		<PopOrderFanjian ref="fanjian" @success="loadData"></PopOrderFanjian>

		<!-- 促好评 -->
		<PopOrderCuhaoping ref="cuhaoping" @success="loadData"></PopOrderCuhaoping>

		<!-- 申请售后 -->
		<PopAfterSalesApply ref="PopAfterSalesApply" :show:sync="showPopAfterSalesApply" @applySuccess="loadData"></PopAfterSalesApply>

		<!-- 取消订单填写备注 -->
		<PopTextarea ref="PopTextarea" :show:sync="showPopTextarea"></PopTextarea>

		<!-- 物流信息 -->
		<!-- <PopOrderWuliuList ref="wuliulist"></PopOrderWuliuList> -->

		<!-- <div class="kefu-online">
			<div class="header">在线客服</div>
			<div class="body"></div>
		</div> -->
	</div>
</template>

<script>
import { OrderList, OrderDealtWithOrder, OrderCancel, OrderDetail, OrderConfirmation, OrderClickCopy, OrderLogistics, Config } from '../common/api.js';
import { download } from '../common/request.js';
import PopOrderCustomerModify from '../components/PopOrderCustomerModify.vue';
import PopOrderUrgent from '../components/PopOrderUrgent.vue';
import PopCustomerService from '../components/PopCustomerService.vue';
import PopOrderReminder from '../components/PopOrderReminder.vue';
import WeixuiPop from '@/components/WeixiuPop';
import PopOrderWangong from '@/components/PopOrderWangong';
import PopOrderFanjian from '@/components/PopOrderFanjian';
import PopOrderCuhaoping from '@/components/PopOrderCuhaoping';
import PopAfterSalesApply from '@/components/PopAfterSalesApply';
import PopTextarea from '@/components/PopTextarea';
import PopOrderWuliuList from '@/components/PopOrderWuliuList';
import { XKFOpen } from '@/common/xiaokefu.js';

import moment from 'moment';

var form = {
	keyWords: '', // 关键词搜索
	label: '', // 标签 （加急，保内，保外，安装，维修，清洗，调试/定位/检测)
	node: '', // 订单节点 支付成功 210 指派师傅 220 指派师傅确认 230 指派师傅拒绝 240 师傅接单 310 预约上门 410 修改时间 420 打卡成功 510 安装材料提交 520 待配件 530 配件已发货 540 配件已到达 550 完工申请 560 保外付款成功 572 评价完成 670 订单完成 710 返件待申核 630 返件申请驳回 634 促好评待审核 650 返件申核通过 640 促评申核通过 660 促评申核驳回 664 已关闭 810 待审核 910 同意售后920(售后指派师傅确认 920220 售后指派师傅确认 920230 售后指派师傅拒绝 920240 售后预约上门 920410 售后修改时间 920420 售后打卡成功 920510 售后安装材料提交 920520 售后待配件 920530 售后配件已发货 920540 售后配件已到达 920550 售后完工申请 920560 售后保外付款成功 920572 售后返件待申核 920630 售后返件申核通过 920640) 售后申请驳回 930 售后完成 940
	status: '', // 订单状态 待付款100,待接单200,待预约300,待施工400,施工中500,待验收600,已完成700,已关闭800,售后900
	date: '', // 日期
	createTime: [], // 下单时间
	completionTime: [] // 完工时间
};
export default {
	name: 'order',
	components: {
		PopOrderCuhaoping,
		PopOrderFanjian,
		PopOrderWangong,
		WeixuiPop,
		PopOrderCustomerModify,
		PopOrderUrgent,
		PopCustomerService,
		PopOrderReminder,
		PopAfterSalesApply,
		PopTextarea,
		PopOrderWuliuList
	},
	data() {
		return {
			orderList: [],
			form: {
				...form
			},
			orderStatusList: [
				{
					label: '全选',
					value: '',
					checked: false
				},
				...this.$store.state.orderStatusList.map((v) => {
					v.checked = false;
					return v;
				})
			],
			orderNodeList: [
				{
					label: '全选',
					value: '',
					checked: false
				},
				...this.$store.state.orderNodeList.map((v) => {
					v.checked = false;
					return v;
				})
			],
			orderLabelList: [
				{
					label: '全部',
					value: '',
					checked: false
				},
				...this.$store.state.orderLabelList
			],
			orderDateList: [
				{
					label: '全部',
					value: '',
					checked: false
				},
				...this.$store.state.orderDateList
			],

			total: 0,
			pageNum: 1,
			pageSize: 50,

			orderDealtWithOrder: '',

			showPopOrderCustomerModify: false,
			showOrderCancel: false,
			showPopOrderUrgent: false,
			showPopCustomerService: false,
			showPopOrderReminder: false,
			showPopAfterSalesApply: false,
			showPopTextarea: false,

			isLoading: true,

			scrollPosition: 0, // 存储滚动位置

			wuliulist: [],
			qudaolink: '',
			kefuPhone: []
		};
	},
	computed: {
		checkedAll() {
			if (this.orderList && this.orderList.length) {
				if (this.orderList.some((v) => v.checked == false)) {
					return false;
				} else {
					return true;
				}
			} else {
				return false;
			}
		}
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			// console.log('this.scrollPosition: ', vm.scrollPosition);
			vm.$nextTick(() => {
				window.scrollTo(0, vm.scrollPosition);
			});
			console.log('vm.$route.params.status', vm.$route.params.status);
			if (vm.$route.params.status) {
				vm.pageNum = 1;
				vm.form.status = vm.$route.params.status == -1 ? '' : vm.$route.params.status;
				vm.loadData();
			} else {
				vm.loadData('created');
			}
		});
	},
	beforeRouteLeave(to, from, next) {
		this.scrollPosition = window.scrollY;
		// console.log('this.scrollPosition: ', this.scrollPosition);
		next();
	},
	created() {
		this.getOrderDealtWithOrder();
		Config('qudaolink').then((res) => {
			this.qudaolink = res.data.configValue;
		});
		Config('kefuPhone').then((res) => {
			this.kefuPhone = res.data.configValue.split(',');
		});
	},
	mounted() {
		// console.log(this.$moment().format("YYYY-MM-DD HH:mm:ss"));
	},
	methods: {
		checkWait() {
			this.orderStatusList[0].checked = false;
			this.orderStatusList.forEach((v) => {
				if (v.value == 200 || v.value == 300 || v.value == 400 || v.value == 500 || v.value == 600 || v.value == 700 || v.value == 900) {
					v.checked = true;
				}
			});
			let orderStatusListCheckeds = this.orderStatusList.filter((v) => v.value && v.checked);
			this.form.status = orderStatusListCheckeds.map((v) => v.value).join(',');
			this.orderNodeList[0].checked = false;
			this.orderNodeList.forEach((v) => {
				if (v.value == 530 || v.value == 630) {
					v.checked = true;
				}
			});
			let orderNodeListCheckeds = this.orderNodeList.filter((v) => v.value && v.checked);
			this.form.node = orderNodeListCheckeds.map((v) => v.value).join(',');
			this.reload();
		},
		wuliuPopoverShow(orderListitem) {
			OrderLogistics(orderListitem.id)
				.then((res) => {
					this.wuliulist = res.data;
					// this.wuliulist = [...res.data,...res.data]
				})
				.catch(() => {});
		},
		reload() {
			this.pageNum = 1;
			this.loadData();
		},
		loadData(type) {
			console.log('this.pageNum: ', this.pageNum);
			let data = {
				keyWords: this.form.keyWords, // 关键词搜索
				label: this.form.label, // 标签 （加急，保内，保外，安装，维修，清洗，调试/定位/检测)
				maxCompletionTime: '', // 完工时间结束
				maxCreateTime: '', // 下单时间结束
				minCompletionTime: '', // 完工时间开始
				minCreateTime: '', // 下单时间开始
				// 订单节点 支付成功 210 指派师傅 220 指派师傅确认 230 指派师傅拒绝 240 师傅接单 310 预约上门 410 修改时间 420 打卡成功 510 安装材料提交 520 待配件 530 配件已发货 540 配件已到达 550 完工申请 560 保外付款成功 572 评价完成 670 订单完成 710 返件待申核 630 返件申请驳回 634 促好评待审核 650 返件申核通过 640 促评申核通过 660 促评申核驳回 664 已关闭 810 待审核 910 同意售后920(售后指派师傅确认 920220 售后指派师傅确认 920230 售后指派师傅拒绝 920240 售后预约上门 920410 售后修改时间 920420 售后打卡成功 920510 售后安装材料提交 920520 售后待配件 920530 售后配件已发货 920540 售后配件已到达 920550 售后完工申请 920560 售后保外付款成功 920572 售后返件待申核 920630 售后返件申核通过 920640) 售后申请驳回 930 售后完成 940
				node: '',
				// 订单状态 待付款100,待接单200,待预约300,待施工400,施工中500,待验收600,已完成700,已关闭800,售后900
				status: '',
				pageSize: this.pageSize,
				pageNum: this.pageNum
			};

			data.status = this.orderStatusList[0].checked || !this.form.status ? -1 : this.form.status;
			data.node = this.orderNodeList[0].checked || !this.form.node ? -1 : this.form.node;

			if (this.form.date) {
				let now = new Date();
				if (this.form.date == '近一周') {
					data.minCreateTime = this.$moment(now.setTime(now.getTime() - 3600 * 1000 * 24 * 7)).format('YYYY-MM-DD');
				} else if (this.form.date == '近一个月') {
					data.minCreateTime = this.$moment(now.setTime(now.getTime() - 3600 * 1000 * 24 * 30)).format('YYYY-MM-DD');
				} else if (this.form.date == '近三个月') {
					data.minCreateTime = this.$moment(now.setTime(now.getTime() - 3600 * 1000 * 24 * 90)).format('YYYY-MM-DD');
				} else if (this.form.date == '近半年') {
					data.minCreateTime = this.$moment(now.setTime(now.getTime() - 3600 * 1000 * 24 * 180)).format('YYYY-MM-DD');
				} else if (this.form.date == '近一年') {
					data.minCreateTime = this.$moment(now.setTime(now.getTime() - 3600 * 1000 * 24 * 365)).format('YYYY-MM-DD');
				}
				data.maxCreateTime = this.$moment(now).format('YYYY-MM-DD');
			} else {
				data.minCreateTime = this.form.createTime[0] || '';
				data.maxCreateTime = this.form.createTime[1] || '';
			}

			data.minCompletionTime = this.form.completionTime[0] || '';
			data.maxCompletionTime = this.form.completionTime[1] || '';

			// console.log('data: ', data);

			if (type == 'export') {
				let orderList = this.orderList.filter((v) => v.checked);
				data.listOrderId = orderList.map((v) => v.id);
				download(
					'/api/app/order/export',
					{
						...data
					},
					`工单记录_${moment().format('YYYY_MM_DD HH_mm_ss')}.xlsx`
				);
			} else {
				if (type != 'created' && type != 'nobackTop') {
					this.isLoading = true;
				}
				OrderList(data)
					.then((res) => {
						this.orderList = res.rows.map((v) => {
							v.checked = false;

							// 虚拟接单数据
							if (v.virtualTaking == 1) {
								if (!v.doorTime) {
									v.node = 310;
									v.status = 300;
								} else {
									v.node = 510;
									v.status = 500;
								}
							}

							return v;
						});
						this.total = res.total;
						if (type != 'created' && type != 'nobackTop') {
							this.$bus.$emit('backTop');
						}
					})
					.finally(() => {
						this.isLoading = false;
					});
			}
		},
		// 查询待处理工单
		getOrderDealtWithOrder() {
			OrderDealtWithOrder().then((res) => {
				this.orderDealtWithOrder = res.data;
			});
		},
		/**
		 * 安装维修材料提交
		 */
		openWeixiucailiao(orderListitem) {
			this.$refs.weixiucailiao.open(orderListitem.id, orderListitem.serviceType);
		},
		// 下单日期变化
		createTimeChange() {
			this.form.date = '';
		},
		// 筛选
		filter() {
			this.reload();
		},
		// 重置
		reset() {
			this.form = {
				...form
			};
			this.orderStatusList.forEach((v) => (v.checked = false));
			this.orderNodeList.forEach((v) => (v.checked = false));

			this.reload();
		},
		filterItemClick(key, item, index) {
			if (key == 'status' || key == 'node') {
				let len = 0;
				let list = [];
				if (key == 'status') {
					list = this.orderStatusList;
				} else if (key == 'node') {
					list = this.orderNodeList;
				}
				if (this.form[key]) {
					len = this.form[key].split(',').length;
				}
				if (!len) {
					if (index == 0) {
						list.forEach((v) => (v.checked = true));
						let value = list
							.filter((v) => v.value && v.checked)
							.map((v) => v.value)
							.join(',');
						this.form[key] = value;
					} else {
						item.checked = !item.checked;
						let checkeds = list.filter((v) => v.value && v.checked);
						if (checkeds.length < list.length - 1) {
							list[0].checked = false;
						} else {
							list[0].checked = true;
						}
						let value = checkeds.map((v) => v.value).join(',');
						this.form[key] = value;
					}
				} else {
					if (index == 0) {
						if (!item.checked) {
							list.forEach((v) => (v.checked = true));
							let value = list
								.filter((v) => v.value && v.checked)
								.map((v) => v.value)
								.join(',');
							this.form[key] = value;
						} else {
							list.forEach((v) => (v.checked = false));
							this.form[key] = '';
						}
					} else {
						item.checked = !item.checked;
						let checkeds = list.filter((v) => v.value && v.checked);
						if (checkeds.length < list.length - 1) {
							list[0].checked = false;
						} else {
							list[0].checked = true;
						}
						let value = checkeds.map((v) => v.value).join(',');
						this.form[key] = value;
					}
				}

				if (key == 'status') {
					this.orderStatusList = list;
				} else if (key == 'node') {
					this.orderNodeList = list;
				}
			} else {
				this.form[key] = item.value;
			}

			console.log('this.form[key]: ', this.form[key]);

			if (key == 'date') {
				this.form.createTime = [];
			}
			this.reload();
		},
		toOrderDetail(orderListitem) {
			this.$router.push({
				name: 'orderDetail',
				query: {
					id: orderListitem.id
				}
			});
		},
		openPopOrderCustomerModify(orderListitem) {
			this.$refs.PopOrderCustomerModify.open(orderListitem);
		},
		openPopAfterSalesApply(orderListitem) {
			this.$refs.PopAfterSalesApply.open(orderListitem);
		},
		copy(orderListitem, orderListindex) {
			// let content = orderListitem.copyText
			OrderClickCopy(orderListitem.id).then((res) => {
				this.copyData(res.data);
			});
		},
		// 取消订单
		openOrderCancel(orderListitem) {
			// this.showOrderCancel = true
			let orderList = [orderListitem];
			this.$refs.OrderCancel.open({
				orderList: orderList,
				confirm: () => {
					this.$refs.PopTextarea.open({
						confirm: (e) => {
							let ids = orderList.map((v) => v.id);
							let data = {
								ids: ids,
								closeRemark: e.content
							};
							OrderCancel(data).then((res) => {
								this.toast({
									message: '取消成功',
									type: 'success'
								});
								this.loadData();
							});
						}
					});
				}
			});
		},
		openOrderCancelForSelect() {
			let orderList = this.orderList.filter((v) => v.checked);
			if (orderList && orderList.length) {
				this.$refs.OrderCancel.open({
					orderList: orderList,
					confirm: () => {
						this.$refs.PopTextarea.open({
							confirm: (e) => {
								let ids = orderList.map((v) => v.id);
								let data = {
									ids: ids,
									closeRemark: e.content
								};
								OrderCancel(data).then((res) => {
									this.toast({
										message: '取消成功',
										type: 'success'
									});
									this.loadData();
								});
							}
						});
					}
				});
			} else {
				this.toast({
					message: '请勾选需要取消的订单',
					type: 'warning'
				});
			}
		},
		// 立即付款
		toOrderPay(orderListitem) {
			this.$router.push({
				name: 'orderPay',
				query: {
					id: orderListitem.id
				}
			});
		},
		// 加急
		openPopOrderUrgent(orderListitem) {
			this.$refs.PopOrderUrgent.open(orderListitem);
		},
		// 催单
		openPopOrderReminder(orderListitem) {
			this.$refs.PopOrderReminder.open({
				id: orderListitem.id
			});
		},
		// 客服
		openKeFu(orderListitem) {
			let { name: nickName, phone: mobile, avatar: avatarUrl } = this.userInfo;
			let { orderNo: tmporderid, createTime: tmpordertime } = orderListitem;
			let { categoryName: tmpTitle, productPicture: tmporderThumb, number: tmpordernum } = orderListitem.productList[0];
			let tmporderprice = orderListitem.serviceType == 5 ? 0 : orderListitem.totalPrice;
			let data = {
				nickName,
				mobile,
				avatarUrl,
				tmporderid,
				tmpordertime,
				tmporderprice,
				tmpTitle,
				tmporderThumb,
				tmpordernum
			};
			XKFOpen(data);

			// setTimeout(() => {
			// 	this.$bus.$emit('XKFSendOrder', {
			// 		content: tmporderid
			// 	});
			// }, 3000);
		},
		// 客服
		openPopCustomerService(orderListitem) {
			OrderDetail(orderListitem.id).then((res) => {
				this.$refs.PopCustomerService.open(res.data.customerServiceUser);
			});
		},
		/** 导出按钮操作 */
		handleExport() {
			this.loadData('export');
		},
		toOrderCreate(data) {
			if (data) {
				this.$store.commit('changeAgainOrder', data);
				this.$router.push({
					name: 'orderCreate',
					query: {
						type: 'againOrder'
					}
				});
			} else {
				this.$router.push({
					name: 'orderCreate'
				});
			}
		},
		setOrderConfirmation(orderListitem) {
			this.$rootApp.$refs.PopTip.open({
				content: '是否确认已经完工？',
				type: 'warning',
				confirm: () => {
					OrderConfirmation(orderListitem.id).then((res) => {
						this.loadData();
					});
				}
			});
		},
		orderListitemSelect(orderListitem, orderListindex) {
			orderListitem.checked = !orderListitem.checked;
		},
		checkedAllClick() {
			if (!this.checkedAll) {
				this.orderList.forEach((v) => (v.checked = true));
			} else {
				this.orderList.forEach((v) => (v.checked = false));
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.kefu-online {
	width: 500px;
	height: 500px;
	position: fixed;
	bottom: 100px;
	right: 100px;
	background-color: green;
	.header {
	}
	.body {
	}
}
.wuliu-popover-content {
	max-width: 970px;
}
.riji-popover-content {
	max-width: 500px;
}

.feiyongmingxi {
	.fy-item {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.fyi-label {
			font-size: 14px;
			font-family: Microsoft YaHei, Microsoft YaHei;
			font-weight: 400;
			color: #333333;
			line-height: 32px;
		}

		.fyi-value {
			font-size: 14px;
			font-family: Microsoft YaHei, Microsoft YaHei;
			font-weight: bold;
			color: #333333;
			line-height: 32px;
		}
	}
}

.kefuPhone {
	.fy-item {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.fyi-label {
			font-size: 14px;
			font-family: Microsoft YaHei, Microsoft YaHei;
			font-weight: 400;
			color: #333333;
			line-height: 32px;
		}

		.fyi-value {
			font-size: 14px;
			font-family: Microsoft YaHei, Microsoft YaHei;
			font-weight: bold;
			color: #333333;
			line-height: 32px;
		}
	}
}

/deep/ .pop-body.OrderCancel {
	box-sizing: border-box;
	padding: 50px 40px;
	display: flex;
	flex-direction: column;
	align-items: center;

	.pb-icon {
		width: 42px;
		height: 42px;
		background-image: url('../assets/066.png');
		background-size: 100% 100%;
	}

	.pb-content {
		margin-top: 20px;
		font-size: 16px;
		font-family: Microsoft YaHei, Microsoft YaHei;
		font-weight: 400;
		color: #333333;
	}

	.pb-fields {
		display: flex;
		align-items: center;
		margin-top: 20px;

		.pbf-item {
			margin: 0 20px;
			font-size: 12px;
			font-family: Microsoft YaHei, Microsoft YaHei;
			font-weight: 400;
			color: #999999;
		}
	}
}

.order {
	.card {
		.c-header {
			display: flex;
			align-items: center;
			height: 80px;
			margin: 0 20px;
			border-bottom: 1px solid #ededed;

			.ch-left {
				display: flex;
				align-items: center;

				.ch-headingbox {
					position: relative;

					.chh-text {
						position: relative;
						z-index: 2;
						font-size: 16px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: bold;
						color: #333333;
						line-height: 16px;
					}

					.chh-line {
						position: absolute;
						left: 0;
						bottom: -3px;
						width: 120%;
						height: 7px;
						background: linear-gradient(90deg, #5fb14d 0%, rgba(95, 177, 77, 0) 100%);
						border-radius: 0px 0px 0px 0px;
					}
				}

				.chl-total {
					margin-left: 20px;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					line-height: 14px;

					.highlight {
						color: #ff5a13;
					}

					.blue {
						color: #3571da;
						margin-left: 10px;
						cursor: pointer;
					}
				}
			}

			.ch-right {
				display: flex;
				align-items: center;
				margin-left: 40px;

				.chr-input-box {
					display: flex;
					align-items: center;
					margin-right: 20px;

					.chri-label {
						width: 80px;
						font-size: 12px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #333333;
					}

					/deep/ .input {
						width: 340px;

						input {
							height: 32px;
							font-size: 14px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #333333;
							padding-left: 8px;
						}
					}
				}

				.chr-search-btn {
					display: flex;
					align-items: center;
					width: 84px;
					height: 32px;
					background-color: #3571da;
					border-radius: 4px;
					justify-content: center;

					.chrs-icon {
						background-image: url('../assets/047.png');
						background-size: 100% 100%;
						width: 14px;
						height: 14px;
						margin-right: 10px;
					}

					.chrs-text {
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #ffffff;
						line-height: 14px;
					}
				}
			}
		}

		.c-body {
			width: calc(100% - 40px);
			// width: 970px;
			margin: 0 auto;

			.filter {
				padding: 10px 0;
				border-bottom: 1px solid #ededed;
				margin-bottom: 20px;

				.filter-row {
					padding-top: 10px;
					display: flex;

					.fr-label {
						width: 80px;
						font-size: 12px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #333333;
						line-height: 24px;
					}

					.fr-content {
						flex: 1;
						display: flex;
						flex-wrap: wrap;

						.frc-item {
							font-size: 12px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #333333;
							line-height: 24px;
							padding: 0 8px;
							margin-right: 10px;
							margin-bottom: 10px;

							&.active {
								border-radius: 4px;
								background-color: #3571da;
								color: #ffffff;

								.mul-checkbox {
									.label {
										color: #ffffff;
									}
								}
							}

							.mul-checkbox {
								display: flex;
								align-items: center;

								&:hover {
									.checkbox {
										.no {
											border: 1px solid #3571da;
										}
									}
								}

								.checkbox {
									width: 15px;
									height: 15px;
									// background-color: #FFFFFF;

									.no {
										width: 100%;
										height: 100%;
										background: #ffffff;
										border-radius: 4px 4px 4px 4px;
										border: 1px solid #cdcdcd;
										box-sizing: border-box;
									}

									.yes {
										width: 100%;
										height: 100%;
										background-image: url('../assets/088.png');
										background-size: 100% 100%;
										border-radius: 4px 4px 4px 4px;
										box-sizing: border-box;
										background-color: #ffffff;
									}
								}

								.label {
									margin-left: 5px;
									font-size: 12px;
									font-family: Microsoft YaHei, Microsoft YaHei;
									font-weight: 400;
									color: #333333;
									line-height: 24px;

									.num {
										margin: 0 5px;
									}
								}
							}
						}
					}
				}
			}

			.cb-row {
				display: flex;
				align-items: center;
				box-sizing: border-box;
				// padding-left: 20px;
				margin-bottom: 20px;

				.cbr-input-box {
					display: flex;
					align-items: center;
					margin-right: 20px;

					.cbri-label {
						width: 80px;
						font-size: 12px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #333333;
					}

					/deep/ .date {
						height: 32px;
						padding-left: 8px;
						padding-right: 4px;

						input {
							// height: 32px;
							font-size: 14px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #333333;
							text-align: left;
						}

						.el-input__icon {
							height: initial;
						}

						.el-icon-date {
							order: 1;
						}

						&.type-1 {
							width: 280px;
						}
					}
				}

				.cbr-shaixuan-btn {
					width: 60px;
					height: 32px;
					background: #3571da;
					border-radius: 4px 4px 4px 4px;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #ffffff;
					line-height: 32px;
					text-align: center;
					margin-right: 20px;
				}

				.cbr-reset-btn {
					width: 60px;
					height: 32px;
					border-radius: 4px 4px 4px 4px;
					border: 1px solid #3571da;
					box-sizing: border-box;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #3571da;
					line-height: 14px;
					display: flex;
					align-items: center;
					justify-content: center;
				}

				.cbr-add-btn {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 84px;
					height: 32px;
					border-radius: 4px 4px 4px 4px;
					border: 1px solid #3571da;
					box-sizing: border-box;

					.cbra-icon {
						width: 16px;
						height: 16px;
						background-image: url('../assets/045.png');
						background-size: 100% 100%;
						margin-right: 5px;
					}

					.cbra-text {
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #3571da;
						line-height: 14px;
					}
				}
			}

			.cb-line {
				border-bottom: 1px solid #ededed;
			}

			.mul-checkbox {
				display: flex;
				align-items: center;

				&:hover {
					.checkbox {
						.no {
							border: 1px solid #3571da;
						}
					}
				}

				.checkbox {
					width: 18px;
					height: 18px;

					.no {
						width: 100%;
						height: 100%;
						background: #ffffff;
						border-radius: 4px 4px 4px 4px;
						border: 1px solid #cdcdcd;
						box-sizing: border-box;
					}

					.yes {
						width: 100%;
						height: 100%;
						background-image: url('../assets/039.png');
						background-size: 100% 100%;
						border-radius: 4px 4px 4px 4px;
						box-sizing: border-box;
					}
				}

				.label {
					margin-left: 10px;
					font-size: 14px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					line-height: 18px;

					.num {
						margin: 0 5px;
					}
				}
			}

			.cb-list {
				.cbl-header {
					padding: 20px 0;
					display: flex;
					align-items: center;

					.mul-checkbox {
						.label {
							font-size: 12px;
						}
					}

					.cblh-hebing {
						width: 88px;
						height: 32px;
						background: #3571da;
						border-radius: 4px 4px 4px 4px;
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #ffffff;
						line-height: 32px;
						text-align: center;
						margin-right: 10px;
						margin-left: 10px;
					}

					.cblh-cancel {
						margin-left: 10px;
						width: 116px;
						height: 32px;
						border-radius: 4px 4px 4px 4px;
						box-sizing: border-box;
						border: 1px solid #3571da;
						font-size: 14px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						color: #3571da;
						line-height: 30px;
						text-align: center;
						margin-right: auto;
					}

					.cbr-add-btn {
						display: flex;
						justify-content: center;
						align-items: center;
						width: 84px;
						height: 32px;
						border-radius: 4px 4px 4px 4px;
						border: 1px solid #3571da;
						box-sizing: border-box;

						.cbra-icon {
							width: 16px;
							height: 16px;
							background-image: url('../assets/045.png');
							background-size: 100% 100%;
							margin-right: 5px;
						}

						.cbra-text {
							font-size: 14px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #3571da;
							line-height: 14px;
						}
					}
				}

				.list-item {
					width: 100%;
					// width: calc(100% - 40px);
					// width: 970px;
					border: 1px solid #dfdfdf;
					margin: 0 auto 20px;
					border-radius: 8px 8px 0 0;

					&:last-child {
						margin-bottom: 0;
					}

					.copy-info {
						.ci-item {
							.cii-label {
							}
						}
					}

					.li-header {
						height: 62px;
						display: flex;
						align-items: center;
						box-sizing: border-box;
						padding: 0 20px;
						border-bottom: 1px solid #dfdfdf;
						background-color: #f7f7f7;

						.lih-field {
							font-size: 14px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #333333;
							line-height: 14px;
							margin-right: 10px;
						}

						.lih-line {
							width: 1px;
							height: 12px;
							background-color: #cccccc;
							margin: 0 8px;
						}

						.lih-date {
							font-size: 12px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #999999;
							line-height: 12px;
							margin-right: 10px;

							&.green {
								color: #5fb14d;
							}
						}

						.lih-jiaji {
							width: 44px;
							height: 22px;
							border-radius: 8px;
							display: flex;
							justify-content: center;
							align-items: center;
							font-size: 14px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							color: #3571da;
							line-height: 14px;
							border: 1px solid #3571da;
							margin-right: 10px;
						}

						.lih-kefu-wrapper {
							display: flex;
							margin-left: auto;
							.lih-kefu {
								display: flex;
								align-items: center;

								&.online {
									margin-right: 30px;
								}

								.lihk-icon {
									margin-right: 10px;
									width: 18px;
									height: 18px;
									background-image: url('../assets/053.png');
									background-size: 100% 100%;
									&.weixin {
										background-image: url('../assets/089.png');
									}
								}

								.lihk-text {
									font-size: 14px;
									font-family: Microsoft YaHei, Microsoft YaHei;
									font-weight: 400;
									color: #333333;
									line-height: 14px;
								}
							}
						}
					}

					.li-table {
						display: flex;

						.column {
							border-right: 1px solid #dfdfdf;
							box-sizing: border-box;
							padding: 20px;

							&.column-1 {
								width: 300px;
								box-sizing: border-box;

								.good-item {
									display: flex;
									margin-bottom: 12px;

									&:last-child {
										margin-bottom: 0;
									}

									.gi-pic {
										width: 92px;
										height: 92px;
										margin-right: 12px;
									}

									.gi-content {
										flex: 1;

										.gic-header {
											display: flex;
											justify-content: space-between;

											.gich-name {
												font-size: 14px;
												font-family: Microsoft YaHei, Microsoft YaHei;
												font-weight: bold;
												color: #333333;
											}

											.gich-price {
												font-size: 14px;
												font-family: Microsoft YaHei, Microsoft YaHei;
												font-weight: bold;
												color: #fc474c;
											}
										}

										.gic-field {
											margin-top: 12px;
											font-size: 12px;
											font-family: Microsoft YaHei, Microsoft YaHei;
											font-weight: 400;
											color: #999999;
											.gich-price {
												font-size: 14px;
												font-family: Microsoft YaHei, Microsoft YaHei;
												font-weight: bold;
												color: #fc474c;
											}
										}
									}
								}
							}

							&.column-1-1 {
								// display: flex;
								// flex-direction: column;
								// align-items: center;
								display: flex;
								flex-direction: column;
								align-items: center;

								.price {
									font-size: 12px;
									font-family: Microsoft YaHei, Microsoft YaHei;
									font-weight: 400;
									color: #999999;
									margin-bottom: 12px;
								}

								.wuliu-popover {
									position: relative;
									width: 100%;
									height: 100%;
								}

								.wuliu-wrap {
									position: relative;
									width: 100%;
									height: 100%;
									display: flex;
									flex-direction: column;
									align-items: center;

									.wuliu-content {
										position: absolute;
										top: 28px;
										width: 100%;
										max-height: calc(100% - 28px);
										font-size: 14px;
										line-height: 16px;
										cursor: pointer;
										&:hover {
											text-decoration: underline;
										}
									}
								}
							}

							&.column-2 {
								display: flex;
								flex-direction: column;
								align-items: center;
								width: 180px;

								.userName {
									font-size: 14px;
									font-family: Microsoft YaHei, Microsoft YaHei;
									font-weight: 400;
									color: #333333;
								}

								.address {
									margin-top: 12px;
									font-size: 12px;
									font-family: Microsoft YaHei, Microsoft YaHei;
									font-weight: 400;
									color: #999999;
								}

								.btns {
									display: flex;
									align-items: center;
									margin-top: 12px;

									.cb-edit {
										width: 20px;
										height: 20px;
										margin: 0 6px;
										background-image: url('../assets/054.png');
										background-size: 100% 100%;
									}

									.cb-copy {
										width: 20px;
										height: 20px;
										margin: 0 6px;
										background-image: url('../assets/055.png');
										background-size: 100% 100%;
									}
								}
							}

							&.column-3 {
								display: flex;
								flex-direction: column;
								align-items: center;
								width: 92px;

								.price {
									font-size: 14px;
									font-family: Microsoft YaHei, Microsoft YaHei;
									font-weight: 400;
									color: #333333;
								}

								.price-detail {
									margin-top: 12px;
									font-size: 12px;
									font-family: Microsoft YaHei, Microsoft YaHei;
									font-weight: 400;
									color: #3571da;
								}
							}

							&.column-4 {
								display: flex;
								flex-direction: column;
								align-items: center;
								width: 100px;
								padding: 20px 0;

								.jindu {
									font-size: 12px;
									font-family: Microsoft YaHei, Microsoft YaHei;
									font-weight: 400;
									color: #999999;
								}

								.jindu-item {
									margin-top: 12px;
									font-size: 14px;
									font-family: Microsoft YaHei, Microsoft YaHei;
									font-weight: 400;
									color: #333333;
								}
							}

							&.column-5 {
								display: flex;
								flex-direction: column;
								align-items: center;
								width: 100px;
								padding: 20px 0;

								.status {
									font-size: 12px;
									font-family: Microsoft YaHei, Microsoft YaHei;
									font-weight: 400;
									color: #999999;
								}

								.status-value {
									margin-top: 12px;
									font-size: 14px;
									font-family: Microsoft YaHei, Microsoft YaHei;
									font-weight: 400;
									color: #ffffff;
									// color: #333333;
									padding: 5px 10px;
									border-radius: 5px;
								}
							}

							&.column-5-1 {
								display: flex;
								flex-direction: column;
								align-items: center;
								width: 100px;
								// height: 50px;
								padding: 20px 10px;

								.status {
									font-size: 12px;
									font-family: Microsoft YaHei, Microsoft YaHei;
									font-weight: 400;
									color: #999999;
									margin-bottom: 12px;
								}

								.riji-popover {
									position: relative;
									width: 100%;
									height: 100%;
								}

								.riji-wrap {
									position: relative;
									width: 100%;
									height: 100%;
									display: flex;
									flex-direction: column;
									align-items: center;

									.riji-content {
										position: absolute;
										top: 28px;
										width: 100%;
										max-height: calc(100% - 28px);
										line-height: 16px;
										font-size: 14px;
									}
								}
							}

							&.column-6 {
								flex: 1;
								display: flex;
								flex-direction: column;
								align-items: center;
								padding: 20px 0;
								border-right: none;

								.btn-option {
									&.type-1 {
										font-size: 14px;
										font-family: Microsoft YaHei, Microsoft YaHei;
										font-weight: 400;
										color: #333333;
										margin-bottom: 12px;
									}

									&.type-2 {
										box-sizing: border-box;
										padding: 0 10px;
										height: 32px;
										background: #3571da;
										border-radius: 4px 4px 4px 4px;
										font-size: 14px;
										font-family: Microsoft YaHei, Microsoft YaHei;
										font-weight: 400;
										color: #ffffff;
										margin-bottom: 12px;
										line-height: 32px;
									}
								}
							}

							&.column-1 {
								width: 18%;
							}

							&.column-1-1 {
								width: 12%;
							}

							&.column-2 {
								width: 16%;
							}

							&.column-3 {
								width: 10%;
							}

							&.column-4 {
								width: 10%;
							}

							&.column-5 {
								width: 9%;
							}

							&.column-5-1 {
								width: 15%;
							}

							&.column-6 {
								width: 10%;
							}
						}
					}
				}
			}

			.pagination-wrap {
				padding: 20px 0;

				/deep/ .pagination {
					display: flex;
					justify-content: center;
				}
			}
		}
	}
}
</style>
